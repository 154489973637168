import { PaymentInformation, SupportContactInformationRecord, paymentClient } from '../client/base';
import { Make } from '../components/common/RetryOptions';
import { logError } from '../utils/logger-utils';

export const getPaymentDataForAnalytics = async (
  paymentId: string
): Promise<PaymentInformation | null> => {
  try {
    const response = await paymentClient.getPaymentDataForAnalytics(paymentId, Make.OneRetry);
    return response.data;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getSupportContactInformation = async (
  paymentId: string
): Promise<SupportContactInformationRecord | null> => {
  try {
    const response = await paymentClient.getSupportContactInformation(paymentId, Make.OneRetry);
    return response.data;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getPaymentStatus = async (paymentId: string) => {
  return await paymentClient.getStatus(paymentId, Make.TwoRetries);
};
