import * as S from './CommonPaymentSideSection.styles';
import useCurrentBreakpoint from '@britishcouncil/react-extro-ui/dist/hooks/useCurrentBreakpoint';
import { ProductsSummaryProps } from '../../../summary/productsSummary';
import ProductsSummary from '../../../summary/productsSummary/ProductsSummary';
import { TimerSection, TimerSectionProps } from '../../../timer';
import ErrorSummaryMessage from '../../../errorSummary/ErrorSummaryMessage';
import { useErrorSummaryContext } from '@contexts';
interface CommonPaymentSideSectionProps {
  timer?: TimerSectionProps;
  productSummary?: ProductsSummaryProps;
}

const CommonPaymentSideSection = ({
  timer,
  productSummary: productsSummary,
}: CommonPaymentSideSectionProps) => {
  const breakpoint = useCurrentBreakpoint();
  const { isErrorSummaryMessageVisible } = useErrorSummaryContext();

  if (breakpoint === 'lg') {
    return (
      <S.StickyRoot className="sticky">
        {isErrorSummaryMessageVisible && <ErrorSummaryMessage />}
        {timer && <TimerSection {...timer} />}
        {productsSummary && <ProductsSummary {...productsSummary} />}
      </S.StickyRoot>
    );
  }

  return (
    <>
      {timer && (
        <S.StickyRoot className="sticky">
          {isErrorSummaryMessageVisible && <ErrorSummaryMessage />}
          <TimerSection {...timer} />
        </S.StickyRoot>
      )}
      <S.Root>{productsSummary && <ProductsSummary {...productsSummary} />}</S.Root>
    </>
  );
};

export default CommonPaymentSideSection;
