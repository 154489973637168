import { hexWithOpacity } from '@britishcouncil/react-common';
import { spacingFn } from '@britishcouncil/react-extro-ui';

const nppTheme = {
  colors: {
    timer: '#CBEDFF',
    processingPanel: hexWithOpacity('#CBEDFF', 0.5),
    fontColorDefault: '#000000',
    order: {
      text: '#4D4D4D'
    },
    discount: {
      background: '#EAFFEF',
      text: '#009637',
      accent: '#21BA45',
    },
  },
  typography: {
    bcSans: 'BCSans, Arial, Helvetica, sans-serif',
  },
  alert: {
    modal: {
      backdrop: 'rgba(30, 30, 30, 0.5)',
      spacing: spacingFn(5),
      cancelPayment: {
        maxWidth: spacingFn(88),
      },
      instalmentsSummary: {
        maxWidth: spacingFn(119),
      },
    },
  },
  layout: {
    stickyContainer: {
      marginTop: 24,
    },
    spacing: {
      footer: {
        marginTop: spacingFn(10),
      },
      paymentPage: {
        gap: spacingFn(6),
        marginFromTop: 0,
      },
      forms: {
        groupGap: spacingFn(9),
      },
    },
  },
};

export default nppTheme;
