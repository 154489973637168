import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`;

export const Image = styled.img`
  max-height: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 300px;
  }
`;
