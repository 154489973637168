import styled from '@emotion/styled';

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const AddressRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const AddressLabel = styled.span`
  font-weight: 700;
  min-width: fit-content;
`;

export const AddressValue = styled.span`
  font-size: ${({ theme }) => theme.fontSize.desktop.body};
  white-space: pre-line;
`;
