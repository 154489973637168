import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.lg};
`;

export const StickyRoot = styled(Root)`
  position: sticky;
  z-index: 1;
  top: ${({ theme }) => theme.spacing.lg};
  order: 1;
`;
