import * as S from './SafePaymentsBadge.styles';

export const SafePaymentsBadge = () => {
  return (
    <S.Root>
      <S.Image
        src="https://bccdn.azureedge.net/dev/online-payment-icons/bc-safe-payments.svg"
        alt="British Council safe payment"
      />
    </S.Root>
  );
};
