import { spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const PaymentPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacingFn(6)};

  & > h4 {
    margin-bottom: ${spacingFn(-3)};
  }

  a {
    font-size: ${({ theme }) => theme.fontSize.default};
    color: ${({ theme }) => theme.colors.indigo};
    text-decoration: none;
  }

  .npp-support-section {
    margin-top: 0;
  }
`;

export const PaymentPageLayoutGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.lg};

  button.confirm-payment-button {
    margin-top: ${({ theme }) => theme.spacing.lg};
  }
`;

export const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.lg};
`;
