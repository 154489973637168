import { useNavigate } from 'react-router';
import { useQueryParams } from './hooks';
import {
  INSTALMENT_PLAN_IDENTIFIER_NAME,
  PAYMENT_IDENTIFIER_NAME,
  SUBSCRIPTION_IDENTIFIER_NAME,
} from '../utils';
import { logError, logEvent } from '../utils/logger-utils';
import {
  InstalmentPlanErrorPath,
  PaymentErrorPagePath,
  SubscriptionErrorPagePath,
} from '../routes/PathConsts';
import { validate as uuidValidate } from 'uuid';

export const useNavigateToErrorPage = () => {
  const navigate = useNavigate();
  const params = useQueryParams();

  const navigateToPaymentErrorPage = (exception?: any, disableTryAgain?: boolean) => {
    const paymentId = params.getPaymentId();
    if (exception) {
      logError(exception);
    }

    logEvent({
      message: 'Redirect to payment error page',
      level: 'warning',
      extra: { paymentId: paymentId, fromUrl: window.location.href },
    });

    if (!uuidValidate(paymentId) || disableTryAgain) {
      navigate(PaymentErrorPagePath);
    } else {
      navigate(`${PaymentErrorPagePath}?${PAYMENT_IDENTIFIER_NAME}=${paymentId}`);
    }
  };

  const goToSubscriptionErrorPage = (exception?: any) => {
    const subscriptionId = params.getSubscriptionId();

    if (exception) {
      logError(exception);
    }

    logEvent({
      message: 'Redirect to subscription error page',
      level: 'warning',
      extra: { subscriptionId: subscriptionId, fromUrl: window.location.href },
    });

    if (!uuidValidate(subscriptionId)) {
      navigate(SubscriptionErrorPagePath);
    } else {
      navigate(`${SubscriptionErrorPagePath}?${SUBSCRIPTION_IDENTIFIER_NAME}=${subscriptionId}`);
    }
  };

  const goToInstalmentPlanErrorPage = (exception?: any) => {
    const instalmentPlanId = params.getInstalmentPlanId();

    if (exception) {
      logError(exception);
    }

    logEvent({
      message: 'Redirect to instalment error page',
      level: 'warning',
      extra: { instalmentPlanId: instalmentPlanId, fromUrl: window.location.href },
    });

    if (!uuidValidate(instalmentPlanId)) {
      navigate(InstalmentPlanErrorPath);
      return;
    }

    navigate(`${InstalmentPlanErrorPath}?${INSTALMENT_PLAN_IDENTIFIER_NAME}=${instalmentPlanId}`);
  };
  return { navigateToPaymentErrorPage, goToSubscriptionErrorPage, goToInstalmentPlanErrorPage };
};
