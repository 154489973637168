import './App.scss';
import React, { Dispatch, SetStateAction, Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { initializeLogging } from './utils/logger-utils';
import { AppSettings } from './utils/appSettings';
import {
  SubscriptionErrorPageRoute,
  SubscriptionSessionTestPageRoute,
  InstalmentPlanErrorPageRoute,
  InstalmentPlanStatusRoute,
  InstalmentPlanSessionTestPageRoute,
  InstalmentPlanPayByCardRoute,
  InstalmentPlanSuccessPageRoute,
  InstalmentPlanUpdatePaymentMethodRoute,
  SubscriptionFailurePageRoute,
  SubscriptionPayByCardRoute,
  SubscriptionSuccessPageRoute,
  SubscriptionUpdatePaymentRoute,
} from './RouteComponents';
import { nppRoutes } from './routes';
import PaymentWrapper from './components/common/wrappers/payment/paymentWrappers';
import { commonPageOptions } from './routes/commonOptions';
import {
  PaymentExpiredPage,
  SessionTestPage,
  PaymentMethodChangeLoadingWrapper,
  GenericErrorPage,
  PaymentErrorPage,
} from './LazyLoadComponents';
import {
  PaymentMethodContextProvider,
  ErrorSummaryContextProvider,
  NotificationsProvider,
} from '@contexts';
import { handleOldRoutes } from './routes/OldRoutes';
import SkeletonLoader from './shared/SkeletonLoader';
import {
  GenericErrorPath,
  InstalmentPlanErrorPath,
  PaymentErrorPagePath,
  SubscriptionErrorPagePath,
} from './routes/PathConsts';
import { CustomCspReload } from './utils/customCspReload';
interface Props {
  settings: AppSettings;
}

type LoadingContextType = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};
const LoadingContext = React.createContext<LoadingContextType>({} as LoadingContextType);
export function useLoadingContext() {
  return useContext<LoadingContextType>(LoadingContext);
}

export const App = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initializeLogging(props.settings);
  }, [props.settings]);

  const getFullRoute = (routeBase: string, subRoute: string) => {
    if (subRoute.length) {
      return `${routeBase}/${subRoute}`;
    }

    return routeBase;
  };

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <NotificationsProvider>
        <Router>
          <ErrorSummaryContextProvider>
            <PaymentMethodContextProvider>
              <CustomCspReload>
                <Routes>
                  <Route
                    path="/stripe/subscription/cardpayment"
                    element={<SubscriptionPayByCardRoute />}
                  />
                  <Route
                    path="/stripe/subscription/success"
                    element={<SubscriptionSuccessPageRoute />}
                  />
                  <Route
                    path="/stripe/subscription/paymentmethod"
                    element={<SubscriptionUpdatePaymentRoute />}
                  />
                  <Route
                    path="/stripe/subscription/failure"
                    element={<SubscriptionFailurePageRoute />}
                  />
                  <Route
                    path="/stripe/instalmentplan/cardpayment"
                    element={<InstalmentPlanPayByCardRoute />}
                  />
                  <Route
                    path="/stripe/instalmentplan/success"
                    element={<InstalmentPlanSuccessPageRoute />}
                  />
                  <Route
                    path="/stripe/instalmentplan/paymentmethod"
                    element={<InstalmentPlanUpdatePaymentMethodRoute />}
                  />
                  <Route
                    path={SubscriptionErrorPagePath}
                    element={<SubscriptionErrorPageRoute />}
                  />
                  <Route
                    path={InstalmentPlanErrorPath}
                    element={<InstalmentPlanErrorPageRoute />}
                  />
                  <Route path="/subscription/stp" element={<SubscriptionSessionTestPageRoute />} />
                  <Route
                    path="/instalmentplan/stp"
                    element={<InstalmentPlanSessionTestPageRoute />}
                  />
                  <Route path="/instalmentplan/status" element={<InstalmentPlanStatusRoute />} />

                  {Object.keys(nppRoutes).map((key) => {
                    const route = nppRoutes[key];
                    return route.subRoutes.map((subRoute) => {
                      if (Array.isArray(subRoute.path)) {
                        return subRoute.path.map((subRoutePath) => {
                          return (
                            <Route
                              key={getFullRoute(route.base, subRoutePath)}
                              path={getFullRoute(route.base, subRoutePath)}
                              element={<PaymentWrapper options={subRoute.options} />}
                            >
                              <Route
                                index
                                element={
                                  <Suspense fallback={<SkeletonLoader />}>
                                    <PaymentMethodChangeLoadingWrapper>
                                      {subRoute.element}
                                    </PaymentMethodChangeLoadingWrapper>
                                  </Suspense>
                                }
                              />
                            </Route>
                          );
                        });
                      }

                      return (
                        <Route
                          key={getFullRoute(route.base, subRoute.path)}
                          path={getFullRoute(route.base, subRoute.path)}
                          element={<PaymentWrapper options={subRoute.options} />}
                        >
                          <Route
                            index
                            element={
                              <Suspense fallback={<SkeletonLoader />}>
                                <PaymentMethodChangeLoadingWrapper>
                                  {subRoute.element}
                                </PaymentMethodChangeLoadingWrapper>
                              </Suspense>
                            }
                          />
                        </Route>
                      );
                    });
                  })}
                  {handleOldRoutes()}
                  <Route path="/" element={<PaymentWrapper />}>
                    <Route path="expired" element={<PaymentExpiredPage />} />
                    <Route path="stp" element={<SessionTestPage />} />
                  </Route>
                  <Route path="/" element={<PaymentWrapper options={commonPageOptions.error} />}>
                    <Route path={PaymentErrorPagePath} element={<PaymentErrorPage />} />
                  </Route>
                  <Route path={GenericErrorPath} element={<GenericErrorPage />} />
                  <Route path="/*" element={<GenericErrorPage />} />
                </Routes>
              </CustomCspReload>
            </PaymentMethodContextProvider>
          </ErrorSummaryContextProvider>
        </Router>
      </NotificationsProvider>
    </LoadingContext.Provider>
  );
};

export interface State {
  errorResponse?: string;
}
