import { spacingFn } from '@britishcouncil/react-extro-ui';
import { theme } from '@britishcouncil/react-extro-ui/dist/theme/theme';
import { Interpolation } from '@emotion/styled';
import { Theme } from 'react-select';

// Styles from ExtroUI Library
const LabelBaseStyles: Interpolation<Theme> = {
  cursor: 'default',
  marginBottom: 0,
  color: theme.colors.text.body,
};

const InputBaseStyles: Interpolation<Theme> = {
  width: '100%',
  color: '#000',
  maxHeight: spacingFn(6),
  fontSize: theme.fontSize.default,
  fontWeight: 400,
  background: '#fff',
  borderRadius: theme.spacing.xs,
  padding: theme.spacing.md,
  border: `1px solid ${theme.colors.lightGrey}`,
  '::placeholder': {
    color: theme.colors.placeholder.default,
  },
  ':hover': {
    border: '1px solid #000',
  },
  ':active': {
    border: `2px solid ${theme.colors.darkBlue}`,
  },
  ':focus': {
    border: `2px solid ${theme.colors.darkBlue}`,
  },
  ':disabled': {
    cursor: 'default',
    color: '#646464',
    background: theme.colors.lightGrey,
    border: 'none',
    '::placeholder': {
      color: 'inherit',
    },
    ':active': {
      border: 'inherit',
    },
    ':focus': {
      border: 'inherit',
    },
  },
  ':read-only': {
    ':not': {
      ':disabled': {
        padding: 0,
        background: 'transparent',
        border: 'none',
      },
    },
  },
};

const InputDisabledStyles: Interpolation<Theme> = {
  cursor: 'default',
  color: '#646464',
  background: theme.colors.lightGrey,
  border: 'none',

  ':hover': {
    border: 'none',
  },
};

const InputErrorStyles: Interpolation<Theme> = {
  color: theme.colors.darkRed,
  border: `1px solid ${theme.colors.darkRed}`,
  '::placeholder': {
    color: `${theme.colors.placeholder.error}`,
  },
  ':active': {
    border: `2px solid ${theme.colors.darkRed}`,

    ':hover': {
      border: `2px solid ${theme.colors.darkRed}`,
    },
  },
  ':focus': {
    border: `2px solid ${theme.colors.darkRed}`,

    ':hover': {
      border: `2px solid ${theme.colors.darkRed}`,
    },
  },
  ':hover': {
    border: `1px solid ${theme.colors.darkRed}`,
  },
};

const InputStyles = {
  base: InputBaseStyles,
  error: InputErrorStyles,
  disabled: InputDisabledStyles,
};

const LabelStyles = {
  base: LabelBaseStyles,
};

export const FieldStyles = {
  input: {
    ...InputStyles,
  },
  label: {
    ...LabelStyles,
  },
};
