import { spacingFn } from '@britishcouncil/react-extro-ui';
import { theme } from '@britishcouncil/react-extro-ui/dist/theme/theme';
import { css } from '@emotion/react';

// Styles from extro-ui lib extracted into classes for styling on-the-fly using class manipulation
export const button = css`
  .ex-btn {
    font-family: Arial;
    font-weight: 700;
    font-size: ${theme.fontSize.default};
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${spacingFn(1.5)} ${spacingFn(5)};
    border-radius: ${theme.spacing.sm};

    > span {
      display: inline-flex;
      align-items: center;
    }

    &:hover:not([disabled]) {
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.5;
      background: ${theme.colors.lightGrey};
      color: ${theme.colors.darkGrey};
    }
  }

  .ex-btn-fill {
    width: 100%;
  }

  .ex-btn-big {
    height: ${spacingFn(8)};
  }

  .ex-btn-compact {
    padding: ${spacingFn(1.5)} ${spacingFn(2.5)};
  }

  .ex-btn-primary {
    background: ${theme.colors.darkBlue};
    color: white;

    &:hover:not([disabled]) {
      background-image: linear-gradient(rgb(0 0 0/20%) 0 0);
    }

    &:active:not([disabled]) {
      background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
    }
  }

  .ex-btn-secondary {
    background: white;
    color: ${theme.colors.darkBlue};
    border: 2px solid ${theme.colors.darkBlue};

    &:hover:not([disabled]) {
      background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
    }

    &:active:not([disabled]) {
      background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
    }

    &[disabled] {
      border: none;
    }
  }

  .ex-btn-discreet {
    background: none;
    color: ${theme.colors.darkBlue};
    padding: ${spacingFn(1.5)} ${spacingFn(2.5)};

    &[disabled] {
      background: none;
    }
  }
`;
