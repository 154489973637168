import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useLoadingContext } from '../../App';
import {
  useNotifications,
  ShowConfirmationModalParams,
  usePaymentMethodContext,
  PaymentMethodChangeStatus,
} from '@contexts';
import { useClient } from '../../client/api-google-analytics';
import { paymentClient, subscriptionClient, instalmentPlanClient } from '../../client/base';
import { GoogleAnalyticsHelpers } from '../../googleAnalytics/GoogleAnalyticsHelpers';
import { PaymentType } from '../paymentType';
import { commonModals } from '..';
import * as S from './BackButton.styles';
import { useDirection } from '../../hooks';

interface BackButtonProps {
  customText?: string;
  hasError?: boolean;
  withConfirm?: boolean;
  customRedirectUrl?: string;
  entity?: {
    id: string;
    type: PaymentType;
  };
  confirmationCustomModalFunc?: () => ShowConfirmationModalParams;
}

export const BackButton = ({
  customText,
  hasError,
  withConfirm,
  customRedirectUrl,
  entity,
  confirmationCustomModalFunc = commonModals.cancelPayment,
}: BackButtonProps) => {
  const { t } = useTranslation(['npp']);
  const { isLoading, setIsLoading } = useLoadingContext();
  const { dataLayerClient } = useClient();
  const { showConfirmModal } = useNotifications();
  const { status } = usePaymentMethodContext();
  const dir = useDirection();

  const onGoBack = async () => {
    if (hasError) {
      window.history.back();
      return;
    }

    if (withConfirm) {
      showConfirmation();
      return;
    }

    await cancelWithRedirect();
  };

  const cancelWithRedirect = async () => {
    setIsLoading(true);
    entity &&
      GoogleAnalyticsHelpers.UpdateDataLayer({
        category: entity.type,
        action: 'Cancel',
      });

    try {
      await updateDataLayer();
    } finally {
      handleCancelWithRedirect();
    }
  };

  const handleCancelWithRedirect = () => {
    const redirectTo = (redirectUrl: string) => {
      window.location.assign(redirectUrl);
    };

    if (customRedirectUrl) {
      redirectTo(customRedirectUrl);
      return;
    }

    if (!entity) {
      return;
    }

    switch (entity.type) {
      case 'Payment':
        entity.id && paymentClient.cancel(entity.id).then((res) => redirectTo(res.data));
        break;
      case 'Subscription':
        entity.id && subscriptionClient.cancel(entity.id).then((res) => redirectTo(res.data));
        break;
      case 'InstalmentPlan':
        entity.id && instalmentPlanClient.cancel(entity.id).then((res) => redirectTo(res.data));
    }
  };

  const showConfirmation = () => {
    showConfirmModal(confirmationCustomModalFunc(), () => {
      cancelWithRedirect();
    });
  };

  const updateDataLayer = async () => {
    const clientId = GoogleAnalyticsHelpers.getCookie('_ga');

    if (clientId) {
      const dataLayer = GoogleAnalyticsHelpers.getClientAppDataLayer();

      await dataLayerClient.post({
        clientId: clientId,
        dataLayer: JSON.stringify(dataLayer),
      });
    }
  };

  return (
    <S.Root
      data-test-id="go-back-button"
      variant="discreet"
      iconLeft={dir === 'ltr' ? <FiArrowLeft size={14} /> : <FiArrowRight size={14} />}
      onClick={onGoBack}
      disabled={isLoading || status === PaymentMethodChangeStatus.Loading}
    >
      {customText ?? t('npp:general.back')}
    </S.Root>
  );
};
