import { BackButton } from '../backButton';
import { PaymentType } from '../paymentType';
import * as S from './HeaderBar.styles';
import { ShowConfirmationModalParams } from '../../contexts/notifications/types';

interface HeaderBarProps {
  withBackButton?: boolean;
  customBackButtonConfirmationModalFunc?: () => ShowConfirmationModalParams;
  customBackButtonText?: string;
  hasError?: boolean;
  withConfirm?: boolean;
  customRedirectUrl?: string;
  entity?: {
    id: string;
    type: PaymentType;
  };
}

export const HeaderBar = ({
  withBackButton,
  customBackButtonConfirmationModalFunc,
  entity,
  customRedirectUrl,
  customBackButtonText,
  hasError,
  withConfirm = true,
}: HeaderBarProps) => {
  return (
    <S.Root withContent={withBackButton}>
      <div className="container">
        <div className="row">
          {withBackButton && (
            <div className="col">
              <BackButton
                customRedirectUrl={customRedirectUrl}
                entity={entity}
                customText={customBackButtonText}
                withConfirm={withConfirm}
                hasError={hasError}
                confirmationCustomModalFunc={customBackButtonConfirmationModalFunc}
              />
            </div>
          )}
        </div>
      </div>
    </S.Root>
  );
};
