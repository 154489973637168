import styled from '@emotion/styled';
import { Root as TimerMessage } from './Timer.styles';
import { spacingFn } from '@britishcouncil/react-extro-ui';

export const Root = styled.div`
  .timer-alert {
    opacity: 1;
    max-height: ${spacingFn(15)};
    margin-bottom: ${({ theme }) => theme.spacing.lg};
    visibility: visible;

    transition:
      opacity ease 0.5s 0.25s,
      max-height ease-out 0.25s,
      padding-top ease-out 0.5s,
      padding-bottom ease-out 0.5s,
      margin-bottom ease 0.25s;
  }

  .timer-alert.hidden {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    margin-bottom: 0px;
    visibility: hidden;
    padding-top: 0;
    padding-bottom: 0;

    transition:
      opacity ease 0.5s,
      max-height ease-out 0.5s 0.5s,
      visibility ease 0.5s,
      padding-top ease-out 0.5s 0.5s,
      padding-bottom ease-out 0.5s 0.5s,
      margin-bottom ease 0.5s 0.5s;
  }
`;

export const TimerAlertMessage = styled(TimerMessage)`
  p {
    font-size: ${({ theme }) => theme.fontSize.desktop.small};
  }
`;

export const TimerAlertTitle = styled.strong`
  font-size: ${({ theme }) => theme.fontSize.mobile.h5};
`;
