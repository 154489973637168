import { spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const StyledErrorSummaryContainer = styled.div`
  border: 6px solid ${({ theme }) => `${theme.colors.darkRed}`};
  border-radius: 15px;
  font-weight: 700;
  ${({ theme }) => theme.elevation[3]};
`;

export const StyledErrorLinkContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.md}  ${theme.spacing.lg} ${spacingFn(5)} ${theme.spacing.lg}`};
`;

export const StyledErrorLink = styled.button`
  color: ${({ theme }) => `${theme.colors.darkRed}`};
  font-size: ${({ theme }) => theme.fontSize.desktop.h5};
  padding-top: 12px;
  :hover {
    cursor: pointer;
  }
  span {
    text-decoration: underline 2px;
  }
`;

export const StyledTitle = styled.p`
  color: ${({ theme }) => `${theme.colors.indigo}`};
  font-size: ${({ theme }) => `${theme.fontSize.mobile.h2}`};
  padding: ${({ theme }) => `${spacingFn(5)} ${theme.spacing.lg} 0 ${theme.spacing.lg}`};
`;
