import { nonceTemplate } from '../../nonce';
import { PAYMENT_IDENTIFIER_NAME, SUBSCRIPTION_IDENTIFIER_NAME, getQueryParam } from '../../utils';
import { Make } from './RetryOptions';

interface LoadCssProps {
  src: string;
  name: string;
  integrity?: string;
  crossorigin?: string;
}

interface LoadScriptProps {
  src: string;
  async: boolean;
  loadTo?: string;
  id?: string;
  integrity?: string;
  crossorigin?: string;
  useNonce?: boolean;
  retries?: Make;
  retryDelay?: number;
}

export const loadScript = (props: LoadScriptProps) => {
  return new Promise((resolve) => {
    const attemptLoadScript = (retryCount: number) => {
      const script = document.createElement('script');
      script.src = props.src;
      script.async = props.async;

      if (props.id) {
        script.id = props.id;
      }

      if (props.useNonce) {
        script.setAttribute('nonce', nonceTemplate);
      }

      if (props.integrity) {
        script.setAttribute('integrity', props.integrity);
      }

      if (props.crossorigin) {
        script.setAttribute('crossorigin', props.crossorigin);
      }

      const loadTarget = document.getElementsByTagName(props.loadTo ?? 'body')[0];
      loadTarget.appendChild(script);

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        loadTarget.removeChild(script); // Remove the failed script
        if (retryCount > 0) {
          setTimeout(() => attemptLoadScript(retryCount - 1), props.retryDelay ?? 1000);
        } else {
          resolve(false);
        }
      };
    };
    attemptLoadScript(props.retries ?? Make.TwoRetries); // Default to 2 retries if not specified
  });
};

export const removeScriptById = (id: string) => {
  const element = document.getElementById(id);
  if (element && element.tagName === 'SCRIPT') element.remove();
};

export const removeScriptByUrl = (url: string) => {
  const element = document.querySelector(`script[src="${url}"]`);
  if (element) element.remove();
};

export const removeElementById = (id: string | string[]) => {
  if (!Array.isArray(id)) {
    const element = document.getElementById(id);
    if (element) element.remove();
    return;
  }

  id.forEach((i) => {
    const element = document.getElementById(i);
    if (element) element.remove();
  });
};

export const loadStyleSheet = (props: LoadCssProps) => {
  const cssId = props.name;
  if (!document.getElementById(cssId)) {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.id = cssId;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = props.src;
    if (props.integrity && props.integrity !== '') {
      link.setAttribute('integrity', props.integrity);
    }
    if (props.crossorigin && props.crossorigin !== '') {
      link.setAttribute('crossorigin', props.crossorigin);
    }
    head.appendChild(link);
  }
};

interface SaveLoadScriptErrorLogProps {
  identifier?: string;
  scriptUrl: string;
  providerName?: string;
}

export const throwScriptLoadError = (props: SaveLoadScriptErrorLogProps) => {
  if (props && !props.identifier) {
    props.identifier = getQueryParam(PAYMENT_IDENTIFIER_NAME);
    if (!props.identifier) {
      props.identifier = getQueryParam(SUBSCRIPTION_IDENTIFIER_NAME);
    }

    throw new Error(
      `${props.providerName}ScriptLoadError Url: ${props.scriptUrl} for id ${props.identifier}`
    );
  }
};
