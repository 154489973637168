import { spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const BiggerTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.mobile.h1};
  color: ${({ theme }) => theme.colors.text.header};
`;

export const StatusPayload = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.desktop.body};
  line-height: ${spacingFn(3.5)};
  font-weight: 400;
`;
