import { Message } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';
import nppTheme from '../styles/nppTheme';

export const Root = styled(Message)`
  ${({ theme }) => theme.elevation[2]};
  font-family: ${nppTheme.typography.bcSans};
  color: ${({ theme }) => theme.colors.red};
  :hover {
    cursor: pointer;
  }
`;
