import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  border-inline-start: 3px solid ${({ theme }) => theme.colors.lightYellow};
`;

export const OrderNumber = styled.h6`
  display: inline;
`;
