import { FiAlertOctagon } from 'react-icons/fi';
import * as S from './TimerAlert.styles';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

export interface TimerAlertProps {
  hidden?: boolean;
}

export const TimerAlert = ({ hidden }: TimerAlertProps) => {
  const { t } = useTranslation(['npp']);

  return (
    <S.Root>
      <S.TimerAlertMessage
        icon={<FiAlertOctagon size={24} />}
        variant="warning"
        className={classNames('timer-alert', { hidden: hidden })}
      >
        <p>
          <S.TimerAlertTitle>{`${t('npp:timer.timeoutWarningTitle')}`}</S.TimerAlertTitle>
          <br />
          <Trans i18nKey="npp:timer.paymentTimeoutWarning" components={{ bold: <strong /> }} />
        </p>
      </S.TimerAlertMessage>
    </S.Root>
  );
};
