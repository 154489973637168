import styled from '@emotion/styled';

export const SummaryTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.desktop.h4};
`;

export const SummarySubtitleWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.sm};
`;

export const SummarySubtitle = styled.h5`
  display: inline;
  font-weight: 400;
  color: #000;
`;

export const SummarySubtitleExtra = styled.p`
  display: inline;
  margin-inline-start: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSize.desktop.body};
`;
