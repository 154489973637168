import React, { PropsWithChildren } from 'react';
import * as S from './Status.styled';
import { MessageVariant } from '@britishcouncil/react-extro-ui';

export type StatusSegmentType = 'success' | 'error' | 'pending' | 'warning' | 'initial';

interface StatusProps extends PropsWithChildren {
  id?: string;
  status: StatusSegmentType;
  text: React.ReactNode;
  amount?: string;
  icon: React.ReactNode;
  iconAlign?: 'header' | 'container';
  color?: string;
  background?: string;
}

const Status = ({
  id,
  status,
  text,
  amount,
  icon,
  children,
  color,
  background,
  iconAlign = 'container',
}: StatusProps) => {
  const getVariant = (): MessageVariant => {
    if (status === 'success') {
      return 'success';
    } else if (status === 'error') {
      return 'error';
    } else if (status === 'pending') {
      return 'info';
    } else if (status === 'warning') {
      return 'warning';
    } else if (status === 'initial') {
      return 'grey';
    }
  };

  return (
    <S.Root
      size="large"
      id={id}
      variant={getVariant()}
      color={color}
      background={background}
      header={
        <S.StatusPayload>
          <h4 data-test-id="ip-summary-status">{text}</h4>
          {amount && <h4>{amount}</h4>}
        </S.StatusPayload>
      }
      icon={icon}
      status={status}
      iconAlign={iconAlign}
    >
      {children}
    </S.Root>
  );
};

export default Status;
