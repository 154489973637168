import { v4 } from 'uuid';
import axios from 'axios';
import { axiosRetryInstances, axios as nppClient } from '../client/base';
import { axios as gaClient } from '../client/api-google-analytics';

export const SessionIdHeader = 'X-Session-Id';
export let Session: string;

type AuditInterceptorsAxiosRequestConfig = { headers?: any };
type AuditInterceptorsAxiosInstance = {
  interceptors: {
    request: {
      use(
        onFulfilled?: (
          value: AuditInterceptorsAxiosRequestConfig
        ) => AuditInterceptorsAxiosRequestConfig
      ): any;
    };
  };
};

export const setAxiosHeaders = (sessionId: string): void => {
  const clients = [axios, nppClient, gaClient, ...axiosRetryInstances];
  if (!sessionId) {
    sessionId = generateNewUuid();
  }
  Session = sessionId;
  for (const client of clients) {
    (client as AuditInterceptorsAxiosInstance).interceptors.request.use((config) => {
      config.headers[SessionIdHeader] = sessionId;
      return config;
    });
  }
};

const generateNewUuid = (): string => {
  return v4();
};
