import { useTranslation } from 'react-i18next';
import * as S from './SummaryProductList.styles';
import { useMemo } from 'react';
import { Divider } from '../../divider';
import { randomUuid } from '../../../utils';

interface SummaryProductListProps {
  title?: React.ReactNode;
  columnHeaders?: string[];
  items?: LineItem[];
  orderId?: string;
  total?: string;
  alwaysShowDivider?: boolean;
}

export interface LineItem {
  label: string | undefined;
  amount: string;
}

const SummaryProductList = ({
  title,
  columnHeaders,
  items,
  orderId,
  total,
  alwaysShowDivider,
}: SummaryProductListProps) => {
  const { t } = useTranslation(['npp']);

  const someItemsHaveLabel = useMemo(() => {
    if (!items || items.length === 0) {
      return false;
    }

    if (items.every((i) => !i.label)) {
      return false;
    }

    return true;
  }, [items]);

  const showItems = !!items?.length && someItemsHaveLabel;

  return (
    <S.Root>
      {title && <S.Title>{title}</S.Title>}
      {showItems && (
        <>
          {columnHeaders && columnHeaders.length > 0 && (
            <S.Header>
              {columnHeaders.map((col) => (
                <h6 key={randomUuid()}>{col}</h6>
              ))}
            </S.Header>
          )}
          <S.List>
            {items.map((c, index) => (
              <S.Item key={randomUuid()}>
                <S.ItemLabel data-clarity-unmask="True">{c.label ?? '—'}</S.ItemLabel>
                <S.ItemAmount data-clarity-unmask="True">{c.amount}</S.ItemAmount>
              </S.Item>
            ))}
          </S.List>
        </>
      )}
      {(showItems || alwaysShowDivider) && <Divider />}
      {total && (
        <S.Total data-clarity-unmask="True">
          <h5>{t('npp:productsSummary.total')}</h5>
          <h5 data-test-id="payment-summary-amount">{total}</h5>
        </S.Total>
      )}
      {orderId && (
        <S.Order>
          {t('npp:productsSummary.orderId')}: <span data-clarity-mask="True">{orderId}</span>
        </S.Order>
      )}
    </S.Root>
  );
};

export default SummaryProductList;
