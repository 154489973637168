import './index.scss';
import './utils/i18n';
import 'babel-polyfill';
import { nonceTemplate } from './nonce';
import createCache from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider, Global } from '@emotion/react';
import * as ReactDOMClient from 'react-dom/client';
import { AppSettingsContext, DEFAULT_APP_SETTINGS } from './utils/appSettings';
import { AppSettingsProvider } from '@britishcouncil/react-common';
import { App } from './App';
import { ExtroProvider } from '@britishcouncil/react-extro-ui';
import { globalNppStyles } from './components';

const nonceCache = createCache({
  key: 'css-nonce',
  nonce: nonceTemplate,
});

const rootEl = document.getElementById('root')!;
const root = ReactDOMClient.createRoot(rootEl);
root.render(
  <EmotionCacheProvider value={nonceCache}>
    <AppSettingsProvider Context={AppSettingsContext} defaultAppSettings={DEFAULT_APP_SETTINGS}>
      <ExtroProvider>
        <Global styles={globalNppStyles} />
        <AppSettingsContext.Consumer>
          {({ settings }) => <App data-clarity-mask="True" settings={settings} />}
        </AppSettingsContext.Consumer>
      </ExtroProvider>
    </AppSettingsProvider>
  </EmotionCacheProvider>
);
