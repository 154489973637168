import { FpxPaymentInfoData } from '../../../client/base';
import { formatUTCDate } from '../../../shared/dataFormater';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface Props {
  fpxPaymentInfo: FpxPaymentInfoData;
}

const StyledDiv = styled.div`
  h2 {
    color: #23085a;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  background-color: #ebeff0;
  padding: 5px 0;
  line-height: 1.8;
  word-wrap: break-word;

  span {
    font-weight: bold;
  }
`;

const FPXPaymentInfo = ({ fpxPaymentInfo }: Props) => {
  const { t } = useTranslation('npp');

  return (
    <StyledDiv>
      <div className="col-xs-12 col-md-8 center-block text-left margin-top-20 margin-bottom-30">
        <div className="row" data-clarity-unmask="true">
          <div className="col-xs-12 col-md-4">
            <h2>{t('npp:fpx.summary.title')}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6" data-clarity-unmask="true">
            <span>{t('npp:fpx.summary.date')}</span>
          </div>
          <div className="col-xs-12 col-md-6">
            {formatUTCDate(fpxPaymentInfo?.transactionDateTime)}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6" data-clarity-unmask="true">
            <span>{t('npp:fpx.summary.amount')}</span>
          </div>
          <div className="col-xs-12 col-md-6">{fpxPaymentInfo?.amountFormatted}</div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6" data-clarity-unmask="true">
            <span>{t('npp:fpx.summary.order')}</span>
          </div>
          <div className="col-xs-12 col-md-6">{fpxPaymentInfo?.sellerOrderNumber}</div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6" data-clarity-unmask="true">
            <span>{t('npp:fpx.summary.transaction')}</span>
          </div>
          <div className="col-xs-12 col-md-6">{fpxPaymentInfo?.fpxTransactionId}</div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6" data-clarity-unmask="true">
            <span>{t('npp:fpx.summary.bank')}</span>
          </div>
          <div className="col-xs-12 col-md-6">{fpxPaymentInfo?.buyerBankName}</div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6" data-clarity-unmask="true">
            <span>{t('npp:fpx.summary.status')}</span>
          </div>
          <div className="col-xs-12 col-md-6">{fpxPaymentInfo?.transactionStatus}</div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default FPXPaymentInfo;
