import styled from '@emotion/styled';

export const Cards = styled.div`
  .provider-name {
    color: ${({ theme }) => theme.colors.indigo};
    font-weight: 700;
  }

  .cards {
    margin-top: ${({ theme }) => theme.spacing.sm};
    display: flex;
    justify-content: center;

    .card {
      padding: ${({ theme }) => theme.spacing.sm};

      .card-image {
        height: ${({ theme }) => theme.spacing.lg};
      }
    }
  }
`;

export const Header = styled.p`
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
  margin-bottom: 0;
`;
