import {
  configureInsightsTracking,
  initInsights,
  logErrorToInsights,
  logEventToInsights,
} from '../components/common/applicatonInsights';
import {
  configureSentryTracking,
  initSentry,
  logErrorToSentry,
  logEventToSentry,
} from '../components/common/sentry';
import { AppSettings } from './appSettings';
import { Clarity } from './clarity';
import { getPublicIp } from './utils';

export interface EventLog {
  message: string;
  extra?: any;
  level: 'debug' | 'info' | 'warning' | 'error' | 'fatal';
}

export enum EntityType {
  payment = 'payment',
  subscription = 'subscription',
  instalmentPlan = 'instalmentPlan',
}

export const initializeLogging = (settings: AppSettings) => {
  if (settings.appInsights.isEnabled) {
    initInsights(settings);
  }

  if (settings.sentry.isEnabled) {
    getPublicIp()
      .then((ip) => {
        initSentry(settings, ip);
      })
      .catch(() => {
        initSentry(settings, '0.0.0.0');
      });
  }

  if (settings.clarity.isEnabled) {
    Clarity.init(settings.clarity.projectId);
  }
};

export const configureTrackingProps = (
  entityId: string,
  entityType: EntityType,
  correlationSessionId: string
) => {
  configureInsightsTracking(entityId, entityType, correlationSessionId);
  configureSentryTracking(entityId, entityType, correlationSessionId);
};

export const logError = (exception: any) => {
  try {
    logErrorToInsights(exception);
  } catch {
    // logging should not causing any error
  }

  try {
    logErrorToSentry(exception);
  } catch {
    // logging should not causing any error
  }
};

export const logEvent = (event: EventLog) => {
  try {
    logEventToInsights(event);
  } catch {
    // logging should not causing any error
  }

  try {
    logEventToSentry(event);
  } catch {
    // logging should not causing any error
  }
};
