import * as S from './CommonLayout.styles';

export interface CommonLayoutProps {
  mainSection: React.ReactNode;
  sideSection?: React.ReactNode;
  topSection?: React.ReactNode;
  alignCenter?: boolean;
}

const CommonLayout = ({ mainSection, sideSection, topSection, alignCenter }: CommonLayoutProps) => {
  return (
    <S.Root className="container" alignCenter={alignCenter}>
      {topSection && <S.TopSection>{topSection}</S.TopSection>}
      <S.Wrapper>
        <S.MainSection>{mainSection}</S.MainSection>
        {sideSection && <S.SideSection>{sideSection}</S.SideSection>}
      </S.Wrapper>
    </S.Root>
  );
};

export default CommonLayout;
