import { FiClock } from 'react-icons/fi';
import * as S from './Timer.styles';
import Countdown, { CountdownRenderProps, CountdownTimeDelta } from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { formatUTCDate, toDateTime } from '../dataFormater';

export interface TimerProps {
  hidden?: boolean;
  timeoutDate: string | Date;
  onComplete: () => void;
  onTick: (timeDelta: CountdownTimeDelta) => void;
  className?: string;
  warningStyleEnabled: boolean;
}

export const Timer = ({
  timeoutDate,
  hidden,
  className,
  onComplete,
  onTick,
  warningStyleEnabled,
}: TimerProps) => {
  const { t } = useTranslation(['npp']);

  const onTickWithTimeoutUpdate = (timeDelta: CountdownTimeDelta) => {
    onTick(timeDelta);
  };

  const renderer = (cdRProps: CountdownRenderProps) => {
    if (cdRProps.days > 0) {
      return (
        <S.TimerMessage>
          {`${t('npp:timer.expireLinkInfo')} `}
          {formatUTCDate(toDateTime(timeoutDate).toJSDate())}
        </S.TimerMessage>
      );
    }

    return (
      <S.TimerMessage className={className}>
        <p>
          {`${t('npp:timer.expireInfo')}`}
          <br />
          <strong data-clarity-unmask="True">
            {cdRProps.hours
              ? `${cdRProps.formatted.hours} ${t('npp:timer.hour')} ${
                  cdRProps.formatted.minutes
                } ${t('npp:timer.minutes')} ${cdRProps.formatted.seconds} ${t('npp:timer.seconds')}`
              : `${cdRProps.formatted.minutes} ${t('npp:timer.minutes')} ${
                  cdRProps.formatted.seconds
                } ${t('npp:timer.seconds')}`}
          </strong>
        </p>
      </S.TimerMessage>
    );
  };

  return (
    <S.Root
      icon={<FiClock size={24} />}
      hidden={hidden}
      variant={warningStyleEnabled ? 'warning' : 'info'}
    >
      <Countdown
        date={timeoutDate}
        renderer={renderer}
        onComplete={onComplete}
        onTick={onTickWithTimeoutUpdate}
      />
    </S.Root>
  );
};
