import { FiCheck, FiTag } from 'react-icons/fi';
import * as S from './DiscountInfoSection.styles';
import { useTranslation } from 'react-i18next';

export interface DiscountInfo {
  name: string;
  discountedAmountFormattedWithCurrency: string;
  discountAmountWithCurrency: string;
  durationInPeriods?: number;
  intervalUnit?: string;
}

interface DiscountInfoSectionProps {
  discount: DiscountInfo;
}

const DiscountInfoSection = ({ discount }: DiscountInfoSectionProps) => {
  const { t } = useTranslation(['npp']);
  const isExpiring = !!discount.durationInPeriods;

  const getCardMessage = () => {
    if (!isExpiring) {
      return t('npp:subscription.discount.appliesForAll');
    }

    if (discount.durationInPeriods === 1) {
      return t('npp:subscription.discount.appliesForFirst');
    }

    return t('npp:subscription.discount.appliesForFirstN', { periods: discount.durationInPeriods });
  };

  return (
    <S.Root elevation={0}>
      <S.TitleWrapper>
        <S.TitleLeftSection>
          <S.TitleIcon>
            <FiTag />
          </S.TitleIcon>
          <S.TitleText>{t('npp:subscription.discount.title')}!</S.TitleText>
        </S.TitleLeftSection>
        <S.TitleDiscountAmount data-clarity-unmask="True">
          - {discount.discountAmountWithCurrency}
        </S.TitleDiscountAmount>
      </S.TitleWrapper>
      <S.DiscountCard>
        <S.DiscountCardIconColumn>
          <FiCheck />
        </S.DiscountCardIconColumn>
        <S.DiscountCardContentColumn>
          <S.DiscountCardTitle data-clarity-mask="True">{discount.name}</S.DiscountCardTitle>
          <S.DiscountCardDescription data-clarity-unmask="True">
            {getCardMessage()}
          </S.DiscountCardDescription>
        </S.DiscountCardContentColumn>
      </S.DiscountCard>
    </S.Root>
  );
};

export default DiscountInfoSection;
