import styled from '@emotion/styled';
import { FiAlertOctagon } from 'react-icons/fi';
import Status from '../../shared/status/common/status/Status';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

interface Props {
  content: string;
}

const Content = styled.div`
  padding-top: ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.fontSize.desktop.body};
  color: ${({ theme }) => theme.colors.darkRed};
`;

const ErrorMsg = (props: Props) => {
  const { t } = useTranslation(['npp']);

  useEffect(() => {
    document
      .getElementById('panel-error-message')
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [props.content]);

  if (!props.content) {
    return null;
  }

  let messageBody = props.content.split('\n');
  const title = messageBody[0];
  messageBody = messageBody.splice(1, messageBody.length);

  const multilineMsg = messageBody.map((item) => <p key={item}>{t(item)}</p>);

  return (
    <Status
      id="panel-error-message"
      status="error"
      text={<span role="alert">{t(title)}</span>}
      icon={<FiAlertOctagon size={40} strokeWidth={1.5} />}
    >
      {multilineMsg.length > 0 && <Content>{multilineMsg}</Content>}
    </Status>
  );
};

export default ErrorMsg;
