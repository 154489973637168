import { spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const InlineTooltipWrapper = styled.div`
  display: flex;
  gap: ${spacingFn(0.75)};
`;

export const Root = styled.span<{ maxWidth?: number }>`
  align-self: center;
  .react-tooltip {
    background: ${({ theme }) => theme.colors.indigo};
    color: #fff;
    font-size: ${({ theme }) => theme.fontSize.mobile.small};
    border-radius: ${({ theme }) => theme.spacing.sm};
    padding: ${spacingFn(0.5)}${spacingFn(1)};
    ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};
    z-index: 2;
  }
`;

export const ChildContainer = styled.a`
  display: flex;
  text-decoration: inherit;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  cursor: help;

  :hover {
    text-decoration: inherit;
    color: inherit;
  }
`;
