import React from 'react';
import styled from '@emotion/styled';

const StyledSectionDivider = styled.div`
  margin: 50px 0;
  display: block;
  width: 100%;
  height: 3px;
  border-bottom: 1px;
  background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;

export const SectionDivider = () => <StyledSectionDivider />;
