import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { EntityType, EventLog } from '../../utils/logger-utils';
import { AppSettings } from '../../utils/appSettings';

let appInsights: ApplicationInsights;
let initialized = false;
const appName = 'BC.NPP.PayService.App';
let trackingProps: {
  application: string;
  environment?: string;
  release?: string;
  correlationSessionId?: string;
  entityType?: 'payment' | 'subscription' | 'instalmentPlan';
  entityId?: string;
  version?: string;
} = {
  application: appName,
};
export const initInsights = (appSettings: AppSettings) => {
  const reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appSettings.appInsights.instrumentationKey,
      enableAutoRouteTracking: false,
      extensions: [reactPlugin],
      enableAjaxErrorStatusText: true,
      enableUnhandledPromiseRejectionTracking: true,
      autoTrackPageVisitTime: true,
      isBrowserLinkTrackingEnabled: true,
      endpointUrl: '/api/log/saveInsights',
    },
  });
  appInsights.loadAppInsights();
  trackingProps = {
    application: appName,
    version: appSettings.version,
    environment: appSettings.environment,
    release: appSettings.release,
  };
  setGlobalHandler();
  initialized = true;
};

//application insights not tracking unhandled errors like sentry so we need to set it manualy
export const setGlobalHandler = () => {
  // Global Error Handler
  window.onerror = function (message, url, lineNo, columnNo, error) {
    appInsights.trackException({
      exception: error,
      properties: { ...trackingProps, message, url, lineNo, columnNo },
    });
    return false;
  };

  // Global Handler for Promise Rejections
  window.addEventListener('unhandledrejection', (event) => {
    appInsights.trackException({
      exception: new Error(`Unhandled promise rejection: ${event.reason}`),
      properties: { ...trackingProps, eventObject: event },
    });
  });
};

export const configureInsightsTracking = (
  entityId: string,
  entityType: EntityType,
  correlationSessionId: string
) => {
  trackingProps.entityId = entityId;
  trackingProps.entityType = entityType;
  trackingProps.correlationSessionId = correlationSessionId;
};

export const startAppInsightTracking = () => {
  if (appInsights) {
    appInsights.trackPageView({
      properties: trackingProps,
      name: appName,
    });
  }
};

export const logErrorToInsights = (exception: any) => {
  //React sometimes pack error in additional object
  let error = exception;
  if (exception.exception) {
    error = exception.exception;
  }

  if (initialized) {
    appInsights.trackException({
      exception: error,
      properties: trackingProps,
    });
  }
};

export const logEventToInsights = (event: EventLog) => {
  if (initialized) {
    appInsights.trackEvent({
      name: event.message,
      properties: {
        ...trackingProps,
        level: event.level,
        additionalData: event.extra,
      },
    });
  }
};
