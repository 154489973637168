import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { ShowConfirmationModalParams, ShowMessageModalParams } from './types';
import { ModalWithButtons, ModalWithButtonsProps } from './modalWithButtons/ModalWithButtons';

type INotificationsContext = {
  showConfirmModal: (params: ShowConfirmationModalParams, onConfirm: () => void) => void;
  showMessageModal: (params: ShowMessageModalParams) => void;
  closeModal: () => void;
};

type NotificationsProviderProps = {
  children: ReactNode;
};

const notificationsContext = createContext<INotificationsContext>({
  showMessageModal: () => {},
  showConfirmModal: () => {},
  closeModal: () => {},
});

export const useNotifications = () => useContext(notificationsContext);

export const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const [currentModal, setCurrentModal] = useState<ModalWithButtonsProps | null>(null);

  const showMessageModal = useCallback(
    ({ title, body, closeButtonText }: ShowMessageModalParams) => {
      setCurrentModal({
        title: title,
        body: body,
        rightButtonText: closeButtonText,
        rightButtonId: 'modal-close',
        onClose: () => {
          setCurrentModal(null);
        },
        onRightButtonClick: () => {
          setCurrentModal(null);
        },
      });
    },
    []
  );

  const showConfirmModal = useCallback(
    (
      { title, body, confirmButtonText, cancelButtonText }: ShowConfirmationModalParams,
      onConfirm: () => void
    ) => {
      setCurrentModal({
        title: title,
        body: body,
        rightButtonText: cancelButtonText,
        leftButtonText: confirmButtonText,
        rightButtonId: 'modal-close',
        leftButtonId: 'modal-confirm',
        onClose: () => {
          setCurrentModal(null);
        },
        onRightButtonClick: () => {
          setCurrentModal(null);
        },
        onLeftButtonClick: () => {
          setCurrentModal(null);
          onConfirm();
        },
      });
    },
    []
  );

  const closeModal = useCallback(() => {
    setCurrentModal(null);
  }, []);

  const providerValue = useMemo(() => {
    return { showMessageModal, showConfirmModal, closeModal };
  }, [showMessageModal, showConfirmModal, closeModal]);

  return (
    <notificationsContext.Provider value={providerValue}>
      {children}
      {currentModal && <ModalWithButtons {...currentModal} />}
    </notificationsContext.Provider>
  );
};
