import styled from '@emotion/styled';
import nppTheme from '../../../../shared/styles/nppTheme';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  footer {
    margin-top: ${nppTheme.layout.spacing.footer.marginTop};
  }
`;
