import { Segment } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const Root = styled(Segment)`
  padding: ${({ theme }) => theme.spacing.xl};
  row-gap: ${({ theme }) => theme.spacing.xl};

  p {
    color: ${({ theme }) => theme.colors.text.body};
  }
`;
