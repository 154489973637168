import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadingContext } from '../../App';
import { useEnterButtonEventListner } from '../../hooks';
import * as S from './ConfirmPaymentButton.styles';

interface Props {
  price?: string;
  disabled?: boolean;
  name?: string;
  id?: string;
  customText?: string;
  onClick?: () => Promise<void> | void;
  formId?: string;
  size?: S.ConfirmPaymentButtonSize;
}

export const ConfirmPaymentButton = ({
  size = 'big',
  customText,
  disabled,
  formId,
  id = 'btn-pay',
  name,
  onClick,
  price,
}: Props) => {
  const { isLoading } = useLoadingContext();
  const { t } = useTranslation(['npp']);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const confirm = () => {
    onClick?.();
    buttonRef.current?.blur();
  };

  useEnterButtonEventListner(() => buttonRef.current?.click());

  return (
  <S.Root
      id={id}
      type="submit"
      className="confirm-payment-button"
      name={name}
      disabled={disabled || isLoading}
      onClick={confirm}
      ref={buttonRef}
      form={formId}
      btnSize={size}
      data-clarity-unmask="True"
    >
      {isLoading
        ? `${t('npp:general.processing')}...`
        : customText ?? `${t('npp:general.pay')} ${price}`}
    </S.Root>
  );
};
