import { useTranslation } from 'react-i18next';
import { InstalmentForStatusData, LineItemForStatusData } from '../../../client/base';
import SummarySegment from '../common/SummarySegment';
import {
  SummarySubtitle,
  SummarySubtitleExtra,
  SummarySubtitleWrapper,
  SummaryTitle,
} from '../common';
import { useNotifications } from '@contexts';
import SummaryProductList from '../common/SummaryProductList';
import { Button, Segment } from '@britishcouncil/react-extro-ui';
import { ConfirmPaymentButton } from '../../confirmPaymentButton';
import PaymentPlan from '../../../components/common/instalmentPlan/PaymentPlan';
import * as S from './InstalmentsSummary.styles';

interface InstalmentsInfo {
  totalAmount: string;
  lineItems: LineItemForStatusData[];
  instalments: InstalmentForStatusData[];
  orderId: string;
}
export interface InstalmentsSummaryProps {
  instalmentsInfo: InstalmentsInfo;
  isUpdatingPaymentMethod?: boolean;
}

const InstalmentsSummary = ({
  instalmentsInfo,
  isUpdatingPaymentMethod,
}: InstalmentsSummaryProps) => {
  const { t } = useTranslation(['npp']);
  const { showMessageModal } = useNotifications();

  const { instalments, lineItems, totalAmount, orderId } = instalmentsInfo;

  const showInstalmentPlanDetails = () => {
    showMessageModal({
      title: t('npp:modals.instalmentsSummary.summaryTitle'),
      body: <PaymentPlan id="instalment-plan-modal" instalments={instalments} withStatuses />,
      closeButtonText: t('npp:general.close'),
    });
  };

  return (
    <SummarySegment data-clarity-unmask="True">
      <Segment color="blue">
        <div>
          <SummaryTitle>{t('npp:stripe.instalmentPlan.instalmentPlan')}</SummaryTitle>
          <SummarySubtitleWrapper>
            <SummarySubtitle data-clarity-unmask="True">{totalAmount}</SummarySubtitle>
            <SummarySubtitleExtra data-clarity-unmask="True">
              / {instalments.length} {t('npp:Instalments')}
            </SummarySubtitleExtra>
          </SummarySubtitleWrapper>
        </div>
        <Segment elevation={0}>
          <SummaryProductList
            title={t('npp:productsSummary.titles.products')}
            columnHeaders={[
              t('npp:productsSummary.description'),
              t('npp:productsSummary.subtotal'),
            ]}
            items={lineItems.map((x) => {
              return {
                label: x.productDescription,
                amount: x.amountFormatted,
              };
            })}
            total={totalAmount}
            orderId={orderId}
          />
        </Segment>
        <Button
          data-test-id="see-instalment-plan-schedule"
          variant="secondary"
          onClick={showInstalmentPlanDetails}
        >
          {t('npp:stripe.instalmentPlan.seeSchedule')}
        </Button>
      </Segment>
      <Segment elevation={0}>
        {!isUpdatingPaymentMethod && (
          <>
            <S.FirstChargeTitleWrapper>
              <h5>{t('npp:stripe.instalmentPlan.firstPayment')}</h5>
              <S.FirstChargeAmount>
                {instalments[0].amountFormattedWithCurrency}
              </S.FirstChargeAmount>
            </S.FirstChargeTitleWrapper>
            <S.FirstChargeDescription>
              {t('npp:stripe.instalmentPlan.firstOfNPayments', {
                instalmentCount: instalments.length,
              })}
            </S.FirstChargeDescription>
          </>
        )}
        <ConfirmPaymentButton
          formId="instalmentPlanForm"
          customText={
            isUpdatingPaymentMethod
              ? t('npp:stripe.instalmentPlan.updatePaymentMethod')
              : t('npp:stripe.instalmentPlan.activate')
          }
        />
      </Segment>
    </SummarySegment>
  );
};

export default InstalmentsSummary;
