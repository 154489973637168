import { useTranslation } from 'react-i18next';
import * as S from './SupportSection.styles';

interface Props {
  supportInfo?: string;
  correlationId?: string;
}

export const SupportSection = ({ supportInfo, correlationId }: Props) => {
  const { t } = useTranslation(['npp']);

  if (!supportInfo || !correlationId) {
    return null;
  }

  return (
    <S.Root data-clarity-unmask="True" className="npp-support-section">
      <h5>{t('npp:general.help.title')}</h5>
      <p>{supportInfo}</p>
      <span>
        {t('npp:general.help.correlationInfo')}{' '}
        <S.OrderNumber data-clarity-mask="True">{correlationId}</S.OrderNumber>
      </span>
    </S.Root>
  );
};
