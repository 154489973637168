import {
  AlePayPaymentPage,
  CyberSourcePaymentPage,
  DokuPaymentPage,
  DragonPayPaymentPage,
  JpmPreparePaymentPage,
  PayByBancontact,
  PayByBankTransfer,
  PayByBlik,
  PayByFPX,
  PayByGiropay,
  PayByMultibanco,
  PayByWeChatPayIntent,
  PayPalPaymentPage,
  PayWithInstalments,
  RedirectMethodPage,
  SeerBitV2PaymentPage,
  SslCommerzPaymentPage,
  StatusPage,
  PayByKlarnaPage,
  AdyenPaymentPage,
  PayByCard,
  OctetPaymentPage,
  PaymentSuccessPage,
  PaymentFailurePage,
  DokuSuccessPage,
  JpmPendingPaymentPage,
  PayDollarRedirectPage,
  PayDollarPendingPage,
  AdyenPendingPage,
  EsewaPendingPage,
  SeerBitV2PaymentPendingPage,
  CyberSourcePendingPage,
  AlePayPendingPage,
  DragonPayPendingPage,
  OctetPendingPage,
  PaymentPendingPage,
  AuthorizePaymentPendingPage,
  MultibancoPaymentStatus,
  PaymentExpiredPage,
  SslCommerzPendingPage,
  BankTransferPaymentData,
  PayByPrzelewy24,
  PayByUpi,
  EsewaPaymentPage,
  PayDollarPaymentPage,
  PayEasePaymentPage,
  PayByPromptPay,
  FiuuPaymentPage,
  PaymobPaymentPage,
  PaymobPendingPage,
  JpmPaymentPage
} from '../LazyLoadComponents';
import { commonPageOptions } from './commonOptions';
import { commonModals } from '../components';
import { NppRoutes, NppSubRoute } from './routes.types';

const stripe: NppSubRoute[] = [
  // Payment pages
  {
    path: 'stripe/multibanco',
    element: <PayByMultibanco />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/multibanco/status',
    element: <MultibancoPaymentStatus />,
    options: commonPageOptions.status,
  },
  {
    path: 'stripe/banktransfer',
    element: <PayByBankTransfer />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/banktransfer/data',
    element: <BankTransferPaymentData />,
    options: commonPageOptions.status,
  },
  {
    path: 'stripe/bancontact',
    element: <PayByBancontact />,
    options: commonPageOptions.payment.newRoutes,
  },

  {
    path: 'stripe/bancontact/pending',
    element: <PaymentPendingPage paymentOption="bancontact" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/blik',
    element: <PayByBlik />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/blik/pending',
    element: <AuthorizePaymentPendingPage paymentMethod="blik" timerInMinutes={1} />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
  {
    path: 'stripe/klarna',
    element: <PayByKlarnaPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/klarna/pending',
    element: <PaymentPendingPage paymentOption="klarna" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/giropay',
    element: <PayByGiropay />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/giropay/pending',
    element: <PaymentPendingPage paymentOption="giropay" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/card',
    element: <PayByCard />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/fpx',
    element: <PayByFPX />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/fpx/pending',
    element: <PaymentPendingPage paymentOption="fpx" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/cardinstalmentmexico',
    element: <PayWithInstalments />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/wechatpay',
    element: <PayByWeChatPayIntent />,
    options: {
      ...commonPageOptions.payment.newRoutes,
      customBackButtonConfirmationModalFunc: commonModals?.cancelQRCodePayment,
    },
  },
  {
    path: ['stripe/grabpay', 'stripe/alipay', 'stripe/ideal', 'stripe/paybybank'],
    element: <RedirectMethodPage />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
  {
    path: 'stripe/przelewy24',
    element: <PayByPrzelewy24 />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/upistripe',
    element: <PayByUpi />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'stripe/promptpay',
    element: <PayByPromptPay />,
    options: {
      ...commonPageOptions.payment.newRoutes,
      customBackButtonConfirmationModalFunc: commonModals?.cancelQRCodePayment,
    },
  },
  {
    path: 'stripe/promptpay/pending',
    element: <PaymentPendingPage paymentOption="promptpay" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  // Pending pages
  {
    path: 'stripe/alipay/pending',
    element: <PaymentPendingPage paymentOption="alipay" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/ideal/pending',
    element: <PaymentPendingPage paymentOption="ideal" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/grabpay/pending',
    element: <PaymentPendingPage paymentOption="grabpay" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/przelewy24/pending',
    element: <PaymentPendingPage paymentOption="przelewy24" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/paybybank/pending',
    element: <PaymentPendingPage paymentOption="paybybank" paymentType="intent" />,
    options: commonPageOptions.pending.timerHidden,
  },
  {
    path: 'stripe/upi/pending',
    element: <AuthorizePaymentPendingPage paymentMethod="upi" timerInMinutes={5} />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
];

const adyen: NppSubRoute[] = [
  {
    path: 'adyen/card',
    element: <AdyenPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: [
      'adyen/payu/pay',
      'adyen/onlinebankingindia/pay',
      'adyen/walletsindia/pay',
      'adyen/paytm/pay',
      'adyen/upi/pay',
    ],
    element: <AdyenPaymentPage />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
  {
    path: [
      'adyen/onlinebankingindia',
      'adyen/paytm',
      'adyen/payu',
      'adyen/walletsindia',
      'adyen/upi',
    ],
    element: <RedirectMethodPage />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },

  {
    path: 'adyen/pending',
    element: <AdyenPendingPage />,
    options: commonPageOptions.pending.timerVisible,
  },
];

const payDollar: NppSubRoute[] = [
  {
    path: 'paydollar/pay',
    element: <PayDollarPaymentPage />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
  {
    path: 'paydollar/redirect',
    element: <PayDollarRedirectPage />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
  {
    path: 'paydollar/pending',
    element: <PayDollarPendingPage />,
    options: commonPageOptions.pending.timerVisible,
  },
  {
    path: 'paydollar',
    element: <RedirectMethodPage />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
];

const esewa: NppSubRoute[] = [
  {
    path: 'esewa/pay',
    element: <EsewaPaymentPage />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
  {
    path: 'esewa/pending',
    element: <EsewaPendingPage />,
    options: commonPageOptions.pending.timerVisible,
  },
  {
    path: 'esewa',
    element: <RedirectMethodPage />,
    options: { ...commonPageOptions.payment.newRoutes, autoTriggerPaymentMethodChange: false },
  },
];

const dragonPay: NppSubRoute[] = [
  {
    path: 'dragonpay/syncbanktransfer',
    element: <DragonPayPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'dragonpay/pending',
    element: <DragonPayPendingPage />,
    options: { ...commonPageOptions.pending.timerVisible, customPaymentIdParam: 'param1' },
  },
];

const sslCommerz: NppSubRoute[] = [
  {
    path: ['sslcommerz/mobilebanking', 'sslcommerz/netbanking', 'sslcommerz/card'],
    element: <SslCommerzPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'sslcommerz/pending',
    element: <SslCommerzPendingPage />,
    options: commonPageOptions.pending.timerVisible,
  },
];

const jpm: NppSubRoute[] = [
  {
    path: 'jpm/card',
    element: <JpmPreparePaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'jpm/card/pay',
    element: <JpmPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'jpm/pending',
    element: <JpmPendingPaymentPage />,
    options: commonPageOptions.pending.timerVisible,
  },
];

const seerbitV2: NppSubRoute[] = [
  {
    path: 'seerbitV2',
    element: <SeerBitV2PaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'seerbitV2/mobilemoney',
    element: <SeerBitV2PaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'seerbitV2/pending',
    element: <SeerBitV2PaymentPendingPage />,
    options: commonPageOptions.pending.timerHidden,
  },
];

const cybersource: NppSubRoute[] = [
  {
    path: 'cybersrc/cybersource',
    element: <CyberSourcePaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'cybersource/pending',
    element: <CyberSourcePendingPage />,
    options: commonPageOptions.pending.timerVisible,
  },
];

const octet: NppSubRoute[] = [
  {
    path: 'octet/card',
    element: <OctetPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'octet/pending',
    element: <OctetPendingPage />,
    options: commonPageOptions.pending.timerVisible,
  },
];

const doku: NppSubRoute[] = [
  { path: 'doku', element: <DokuPaymentPage />, options: commonPageOptions.payment.newRoutes },
  { path: 'doku/success', element: <DokuSuccessPage />, options: commonPageOptions.success },
];

const paypal: NppSubRoute[] = [
  {
    path: 'paypal',
    element: <PayPalPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
];

const payEase: NppSubRoute[] = [
  {
    path: 'payease/alipay',
    element: <PayEasePaymentPage />,
    options: {
      ...commonPageOptions.payment.newRoutes,
      customBackButtonConfirmationModalFunc: commonModals?.cancelQRCodePayment,
    },
  },
];

const alePay: NppSubRoute[] = [
  {
    path: ['alepay/internationalcard', 'alepay/qrcode', 'alepay/vietqr', 'alepay/atmcard'],
    element: <AlePayPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'alepay/pending',
    element: <AlePayPendingPage />,
    options: commonPageOptions.pending.timerVisible,
  },
];

const fiuu: NppSubRoute[] = [
  {
    path: 'fiuu/fiuubanktransfers',
    element: <FiuuPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
];


const paymob: NppSubRoute[] = [
  {
    path: 'paymob/card',
    element: <PaymobPaymentPage />,
    options: commonPageOptions.payment.newRoutes,
  },
  {
    path: 'paymob/pending',
    element: <PaymobPendingPage />,
    options: commonPageOptions.pending.timerVisible,
  },
];

export const nppRoutes: NppRoutes = {
  payment: {
    base: '/payment',
    subRoutes: [
      {
        path: '',
        element: <></>,
        options: {
          ...commonPageOptions.payment.newRoutes,
          autoTriggerPaymentMethodChange: false,
          withSafePaymentsBadge: false,
        },
      },
      ...stripe,
      ...adyen,
      ...payDollar,
      ...esewa,
      ...dragonPay,
      ...sslCommerz,
      ...jpm,
      ...seerbitV2,
      ...cybersource,
      ...octet,
      ...doku,
      ...paypal,
      ...alePay,
      ...payEase,
      ...fiuu,
      ...paymob,
      { path: 'success', element: <PaymentSuccessPage />, options: commonPageOptions.success },
      { path: 'failure', element: <PaymentFailurePage />, options: commonPageOptions.failure },
      { path: 'expired', element: <PaymentExpiredPage />, options: commonPageOptions.failure },
      {
        path: 'status',
        element: <StatusPage />,
        options: commonPageOptions.status,
      },
    ],
  },
};
