import { Message } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';
import nppTheme from '../styles/nppTheme';

export const TimerMessage = styled.span`
  font-size: ${({ theme }) => theme.fontSize.desktop.small};
`;

export const Root = styled(Message)`
  background: ${({ variant }) =>
    variant === 'warning' ? undefined /* default extro warning color */ : nppTheme.colors.timer};
  ${({ theme }) => theme.elevation[2]};
  font-family: ${nppTheme.typography.bcSans};
  color: ${({ theme, variant }) =>
    variant === 'warning' ? theme.colors.brown : theme.colors.indigo};
  transition:
    background-color ease-out 0.5s,
    color ease-out 0.5s;
`;
