import { Segment, spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';
import nppTheme from '../../../styles/nppTheme';

export const Root = styled(Segment)`
  display: flex;
  flex-direction: column;
  background: ${nppTheme.colors.discount.background};
  border-radius: 0;

  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.md};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${nppTheme.colors.discount.text};
`;

export const TitleLeftSection = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const TitleText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.mobile.body};

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: ${({ theme }) => theme.fontSize.desktop.body};
  }
  display: inline;
`;
export const TitleIcon = styled.span`
  font-size: ${({ theme }) => theme.fontSize.desktop.h4};
  display: contents;
`;

export const TitleDiscountAmount = styled.span`
  font-size: ${({ theme }) => theme.fontSize.desktop.h5};
  white-space: nowrap;
`;

export const DiscountCard = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.spacing.sm};
  ${({ theme }) => theme.elevation[2]};
`;

export const DiscountCardContentColumn = styled.div`
  background: #fff;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  border-start-end-radius: ${({ theme }) => theme.spacing.sm};
  border-end-end-radius: ${({ theme }) => theme.spacing.sm};
`;

export const DiscountCardIconColumn = styled.div`
  padding: ${spacingFn(1.5)};
  background: ${nppTheme.colors.discount.accent};
  color: #fff;
  font-size: ${({ theme }) => theme.fontSize.desktop.h4};
  display: flex;
  align-items: center;
  border-start-start-radius: ${({ theme }) => theme.spacing.sm};
  border-end-start-radius: ${({ theme }) => theme.spacing.sm};
`;

export const DiscountCardTitle = styled.h6`
  color: #000;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const DiscountCardDescription = styled.small`
  color: ${({ theme }) => theme.colors.darkGrey};
`;
