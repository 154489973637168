import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { scrollToRef } from '../../utils';
import { ErrorSummaryContextType, ErrorSummaryElement } from './types';

const ErrorSummaryContext = React.createContext<ErrorSummaryContextType | null>(null);

const ErrorSummaryContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [errorsSummary, setErrorsSummary] = useState<ErrorSummaryElement[]>([]);
  const [isErrorSummaryActive, setIsErrorSummaryActive] = useState<boolean>(false);
  const [isErrorSummaryBoxOnScreen, setIsErrorSummaryBoxOnScreen] = useState<boolean>(false);
  const [errorSummaryBoxRef, setErrorSummaryBoxRef] =
    useState<MutableRefObject<HTMLDivElement | null>>();

  const isErrorSummaryBoxVisible = isErrorSummaryActive && errorsSummary.length > 0;
  const isErrorSummaryMessageVisible =
    isErrorSummaryActive && errorsSummary.length > 0 && !isErrorSummaryBoxOnScreen;

  const setErrors = useCallback((errors: (ErrorSummaryElement | undefined)[]) => {
    const filtered = errors.filter(
      (error): error is ErrorSummaryElement => error !== undefined && !!error.errorMessage
    );
    setErrorsSummary(filtered);
  }, []);

  const showErrorSummary = useCallback(() => {
    setIsErrorSummaryActive(true);
  }, []);

  useEffect(() => {
    if (isErrorSummaryActive && errorsSummary.length === 0) setIsErrorSummaryActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsSummary.length]);

  useEffect(() => {
    const currenterrorSummaryBoxRef = errorSummaryBoxRef?.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsErrorSummaryBoxOnScreen(entry.isIntersecting);
      });
    });

    if (currenterrorSummaryBoxRef) {
      observer.observe(currenterrorSummaryBoxRef);
    }

    return () => {
      if (currenterrorSummaryBoxRef) {
        observer.unobserve(currenterrorSummaryBoxRef);
      }
    };
  }, [errorSummaryBoxRef]);

  const scrollToSummaryBox = useCallback(() => {
    if (errorSummaryBoxRef?.current) {
      scrollToRef(errorSummaryBoxRef);
    }
  }, [errorSummaryBoxRef]);

  const clearErrorSummary = useCallback(() => {
    setErrorsSummary([]);
    setIsErrorSummaryActive(false);
    setIsErrorSummaryBoxOnScreen(false);
  }, []);

  useEffect(() => {
    if (isErrorSummaryBoxVisible) scrollToSummaryBox();
  }, [isErrorSummaryBoxVisible, scrollToSummaryBox]);

  const contextValue = useMemo(
    () => ({
      errors: errorsSummary,
      isErrorSummaryBoxVisible,
      isErrorSummaryMessageVisible,
      setErrors,
      showErrorSummary,
      clearErrorSummary,
      errorsNumber: errorsSummary.length,
      refCallback: setErrorSummaryBoxRef,
      scrollToSummaryBox,
    }),
    [
      errorsSummary,
      isErrorSummaryBoxVisible,
      isErrorSummaryMessageVisible,
      scrollToSummaryBox,
      setErrors,
      showErrorSummary,
      clearErrorSummary,
    ]
  );

  return (
    <ErrorSummaryContext.Provider value={contextValue}>{children}</ErrorSummaryContext.Provider>
  );
};

export { ErrorSummaryContext, ErrorSummaryContextProvider };
