import { spacingFn } from '@britishcouncil/react-extro-ui';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div`
  height: ${({ theme }) => theme.spacing.sm};
  overflow: hidden;
  background-color: #ffffff;
  border-radius: ${spacingFn(12.5)};
`;

const KeyFrames = keyframes`
  from {
    inset-inline-start: ${spacingFn(-25)};
    width: 20%;
  }

  50% {
    width: 50%;
  }

  70% {
    width: 70%;
  }

  to {
    inset-inline-start: 100%;
  }
`;

export const Loader = styled.div`
  animation: ${KeyFrames} 2s linear infinite;

  position: relative;
  width: ${spacingFn(25)};
  height: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-radius: ${spacingFn(12.5)};
`;
