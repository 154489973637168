import useCurrentBreakpoint from '@britishcouncil/react-extro-ui/dist/hooks/useCurrentBreakpoint';
import { TimerSectionProps } from '../../..';
import { TimerSection } from '../../../timer';
import * as S from './CommonInstalmentPlanSideSection.styles';
import InstalmentsSummary, {
  InstalmentsSummaryProps,
} from '../../../summary/instalmentsSummary/InstalmentsSummary';

interface CommonInstalmentPlanSideSectionProps {
  timer?: TimerSectionProps;
  instalmentsSummary?: InstalmentsSummaryProps;
  isUpdatingPaymentMethod?: boolean;
}

const CommonInstalmentPlanSideSection = ({
  timer,
  instalmentsSummary,
  isUpdatingPaymentMethod,
}: CommonInstalmentPlanSideSectionProps) => {
  const breakpoint = useCurrentBreakpoint();

  if (breakpoint === 'lg') {
    return (
      <S.StickyRoot className="sticky">
        {timer && <TimerSection {...timer} />}
        {instalmentsSummary && (
          <InstalmentsSummary
            {...instalmentsSummary}
            isUpdatingPaymentMethod={isUpdatingPaymentMethod}
          />
        )}
      </S.StickyRoot>
    );
  }

  return (
    <>
      {timer && (
        <S.StickyRoot className="sticky">
          <TimerSection {...timer} />
        </S.StickyRoot>
      )}
      <S.Root>
        {instalmentsSummary && (
          <InstalmentsSummary
            {...instalmentsSummary}
            isUpdatingPaymentMethod={isUpdatingPaymentMethod}
          />
        )}
      </S.Root>
    </>
  );
};

export default CommonInstalmentPlanSideSection;
