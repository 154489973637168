import React, { useEffect, useMemo, useState } from 'react';
import { BillingDetailsErrorSummaryAggregateContextType, ErrorSummaryElement } from './types';
import { useErrorSummaryContext } from './useErrorSummaryContext';

const BillingDetailsErrorSummaryAggregateContext =
  React.createContext<BillingDetailsErrorSummaryAggregateContextType | null>(null);

const BillingDetailsErrorSummaryAggregateContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [otherErrorsSummary, setOtherErrorsSummary] = useState<ErrorSummaryElement[]>([]);
  const [billingDetailsErrorsSummary, setBillingDetailsErrorsSummary] = useState<
    ErrorSummaryElement[]
  >([]);

  const { setErrors } = useErrorSummaryContext();

  const setOtherErrors = (errors: (ErrorSummaryElement | undefined)[]) => {
    setOtherErrorsSummary(
      errors.filter((error): error is ErrorSummaryElement => error !== undefined)
    );
  };

  const setBillingDetailsErrors = (errors: (ErrorSummaryElement | undefined)[]) => {
    setBillingDetailsErrorsSummary(
      errors.filter((error): error is ErrorSummaryElement => error !== undefined)
    );
  };

  useEffect(() => {
    setErrors([...otherErrorsSummary, ...billingDetailsErrorsSummary]);
  }, [otherErrorsSummary, billingDetailsErrorsSummary, setErrors]);

  const contextValue = useMemo(() => ({ setOtherErrors, setBillingDetailsErrors }), []);

  return (
    <BillingDetailsErrorSummaryAggregateContext.Provider value={contextValue}>
      {children}
    </BillingDetailsErrorSummaryAggregateContext.Provider>
  );
};

export {
  BillingDetailsErrorSummaryAggregateContext,
  BillingDetailsErrorSummaryAggregateContextProvider,
};
