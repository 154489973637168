import { PaymentStatusData } from '../../client/base';
import { AvailablePaymentMethod } from '../../shared';
import { Location } from 'react-router';
import { QueryParams } from '../../utils';

export enum PaymentMethodChangeStatus {
  Init,
  Loading,
  Success,
  Failed,
}

export type PaymentMethodContextType = {
  status: PaymentMethodChangeStatus;
  paymentMethodContextInitialized: boolean;
  forcePaymentMethodChangerDisabled: boolean;
  availablePaymentMethods: AvailablePaymentMethod[];
  chosenPaymentMethod: AvailablePaymentMethod | undefined;
  nextChangeAllowed: boolean;
  triggerPaymentMethodChange: () => Promise<void>;
  setPaymentMethod: (paymentMethod: AvailablePaymentMethod) => void;
  setPaymentMethodChanged: () => void;
  setForcePaymentMethodChangerDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  initializePaymentMethodContext: (model: InitializePaymentMethodContextModel) => Promise<void>;
};

export type InitializePaymentMethodContextModel = {
  paymentId: string;
  queryParams: QueryParams;
  getStatusResponse: PaymentStatusData;
  location: Location;
};
