import styled from '@emotion/styled';

export const FirstChargeTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FirstChargeDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.body};
`;

export const FirstChargeAmount = styled.h5`
  color: #000;
  font-weight: 400;
`;
