import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.md};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.lg};
  row-gap: ${({ theme }) => theme.spacing.lg};
  font-size: ${({ theme }) => theme.fontSize.desktop.body};
`;
