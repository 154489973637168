import styled from '@emotion/styled';

export const FirstChargeTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const FirstChargeAmount = styled.h5`
  color: #000;
  font-weight: 400;
`;

export const FirstChargeRecurringInfo = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: center;
`;

export const TrialNote = styled.small`
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const FirstChargeTitleWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`;
