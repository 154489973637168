import { Message, Segment, colorWithOpacity, spacingFn } from '@britishcouncil/react-extro-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.sm};
`;

export const PaymentMethodLogo = styled(Segment)`
  width: ${spacingFn(7.75)};
  height: ${spacingFn(5.25)};

  img {
    max-height: ${spacingFn(5.25)};
    max-width: ${spacingFn(7.75)};
  }

  border-radius: ${spacingFn(0.5)};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  box-shadow: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.indigo};
`;

export const PaymentMethodSegment = styled(Segment)<{ disabled: boolean }>`
  align-items: center;
  justify-content: center;
  height: ${spacingFn(9)};
  padding: 0;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
        opacity: 0.3;
      `;
    }
  }};

  :focus {
    border: 2px solid ${colorWithOpacity('darkBlue', 0.5)};
  }
`;

export const PaymentMethodList = styled.div`
  > [class^='col'] {
    padding: 0 ${({ theme }) => theme.spacing.sm};
    margin-top: ${({ theme }) => theme.spacing.lg};
  }
`;

export const PaymentMethodChangerMessage = styled(Message)`
  margin-top: ${spacingFn(6)};
`;
