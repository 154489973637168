import styled from '@emotion/styled';
import { theme } from '@britishcouncil/react-extro-ui/dist/theme/theme';
import nppTheme from '../../../shared/styles/nppTheme';
import { Modal, spacingFn } from '@britishcouncil/react-extro-ui';

export const NppModal = styled(Modal)`
  max-height: calc(100vh - ${theme.spacing.xxl});
  margin-top: auto;
  margin-bottom: auto;
  overflow-y: auto;
  max-width: ${spacingFn(119)};
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${theme.spacing.md};
  gap: ${nppTheme.alert.modal.spacing};
`;

export const Body = styled.div`
  font-size: ${theme.fontSize.mobile.h4};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing.md};

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row-reverse;
  }
`;
