import styled from '@emotion/styled';

export const StyledSummaryTable = styled.div`
  @media only screen and (max-width: 450px) {
    td:nth-of-type(1),
    th:nth-of-type(1) {
      display: none;
    }
  }
  overflow-x: auto;

  table {
    background: inherit;
    width: 100%;
  }

  th {
    padding-top: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ddd;
    background-color: inherit;
    line-height: inherit;
  }

  td {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 10px;
    border-bottom: 1px solid #ddd;
  }
`;
