import { PageType } from '../components/common';
import { CommonPageOptions, PaymentPageOptions } from './routes.types';

const commonPaymentPageOptions: PaymentPageOptions = {
  withPaymentMethodChanger: true,
  withTitle: true,
  withTimer: true,
  withSideSection: true,
  withBackButton: true,
  withRedirectOnFinishedPayment: true,
  autoTriggerPaymentMethodChange: true,
  pageType: PageType.Payment,
  withSafePaymentsBadge: true,
};

const commonPendingPageOptions: PaymentPageOptions = {
  withTitle: true,
  withTimer: true,
  pageType: PageType.Status,
  isTimerHidden: true,
  alignCenter: true,
};

const commonSuccessPageOptions: PaymentPageOptions = {
  alignCenter: false,
};

const commonFailurePageOptions: PaymentPageOptions = {
  alignCenter: false,
};

const commonStatusPageOptions: PaymentPageOptions = {
  withTitle: true,
  pageType: PageType.Status,
};

const commonErrorPageOptions: PaymentPageOptions = {
  isErrorPage: true,
};

export const commonPageOptions: CommonPageOptions = {
  payment: {
    newRoutes: { ...commonPaymentPageOptions },
    oldRoutes: { ...commonPaymentPageOptions, autoTriggerPaymentMethodChange: false },
  },
  pending: {
    timerHidden: {
      ...commonPendingPageOptions,
    },
    timerVisible: {
      ...commonPendingPageOptions,
      isTimerHidden: false,
    },
  },
  success: commonSuccessPageOptions,
  status: commonStatusPageOptions,
  failure: commonFailurePageOptions,
  error: commonErrorPageOptions,
};
