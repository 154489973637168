import { lazy } from 'react';
import { lazyWithRetries } from './utils/lazyLoadingUtils';

export const GenericErrorPage = lazyWithRetries(() => import('./components/core/GenericErrorPage'));
export const PaymentErrorPage = lazyWithRetries(() => import('./components/core/PaymentErrorPage'));
export const SubscriptionSuccessPage = lazyWithRetries(
  () => import('./shared/processing/pages/successSubscription/SubscriptionSuccessPage')
);
export const PaymentSuccessPage = lazyWithRetries(
  () => import('./shared/processing/pages/success/SuccessPage')
);
export const PaymentFailurePage = lazyWithRetries(
  () => import('./shared/processing/pages/failure/FailurePage')
);
export const SubscriptionFailurePage = lazyWithRetries(
  () => import('./shared/processing/pages/failureSubscription/SubscriptionFailurePage')
);
export const SubscriptionErrorPage = lazyWithRetries(
  () => import('./components/core/SubscriptionErrorPage')
);
export const InstalmentPlanErrorPage = lazyWithRetries(
  () => import('./components/core/InstalmentPlanErrorPage')
);
export const InstalmentPlanSuccessPage = lazyWithRetries(
  () => import('./shared/processing/pages/sucessInstalmentPlan/InstalmentPlanSuccessPage')
);

export const StatusPage = lazyWithRetries(() => import('./shared/status/pages/payment/StatusPage'));
export const InstalmentPlanStatusPage = lazyWithRetries(
  () => import('./shared/status/pages/instalmentPlan/InstalmentPlanStatusPage')
);

export const PayByCard = lazyWithRetries(() => import('./components/Stripe/PayByCard'));
export const PayByPrzelewy24 = lazyWithRetries(() => import('./components/Stripe/Przelewy24/PayByPrzelewy24'));
export const PayByPromptPay = lazyWithRetries(() => import('./components/Stripe/PromptPay/PayByPromptPay'));
export const PayByWeChatPayIntent = lazyWithRetries(
  () => import('./components/Stripe/PayByWeChatPayIntent')
);
export const PaymentPendingPage = lazyWithRetries(
  () => import('./components/Stripe/PaymentPendingPage')
);
export const PayByGiropay = lazyWithRetries(() => import('./components/Stripe/Giropay/PayByGiropay'));
export const PayByBlik = lazyWithRetries(() => import('./components/Stripe/Blik/PayByBlik'));
export const PayByUpi = lazyWithRetries(() => import('./components/Stripe/Upi/PayByUpi'));
export const PayByFPX = lazyWithRetries(() => import('./components/Stripe/Fpx/PayByFPX'));
export const FPXPaymentSuccessPage = lazyWithRetries(() => import('./components/Stripe/PayByCard'));
export const PayWithInstalments = lazyWithRetries(
  () => import('./components/Stripe/CardInstalments/PayWithInstalments')
);
export const PayByMultibanco = lazyWithRetries(
  () => import('./components/Stripe/Multibanco/PayByMultibanco')
);
export const PayByBancontact = lazyWithRetries(() => import('./components/Stripe/Bancontact/PayByBancontact'));
export const PayByBankTransfer = lazyWithRetries(
  () => import('./components/Stripe/PayByBankTransfer')
);
export const SubscriptionPayByCard = lazyWithRetries(
  () => import('./components/Stripe/Subscriptions/SubscriptionPayByCard')
);
export const InstalmentPlanPayByCard = lazyWithRetries(
  () => import('./components/Stripe/InstalmentPlans/InstalmentPlanPayByCard')
);
export const InstalmentPlanUpdatePaymentMethod = lazyWithRetries(
  () => import('./components/Stripe/InstalmentPlans/InstalmentPlanUpdatePaymentMethod')
);
export const MultibancoPaymentStatus = lazyWithRetries(
  () => import('./components/Stripe/Multibanco/MultibancoPaymentStatus')
);
export const BankTransferPaymentData = lazyWithRetries(
  () => import('./components/Stripe/PayByBankTransfer/BankTransferPaymentData')
);

export const AuthorizePaymentPendingPage = lazyWithRetries(
  () => import('./components/Stripe/AuthorizePaymentPendingPage')
);

export const DokuSuccessPage = lazyWithRetries(() => import('./components/Doku/SuccessPage'));
export const DokuPaymentPage = lazyWithRetries(() => import('./components/Doku/PaymentPage'));

export const AdyenPaymentPage = lazyWithRetries(() => import('./components/Adyen/PaymentPage'));
export const AdyenPendingPage = lazyWithRetries(() => import('./components/Adyen/PendingPage'));

export const PayPalPaymentPage = lazyWithRetries(() => import('./components/PayPal/PaymentPage'));

export const SeerBitV2PaymentPage = lazyWithRetries(
  () => import('./components/SeerBitV2/PaymentPage')
);
export const SeerBitV2PaymentPendingPage = lazy(
  () => import('./components/SeerBitV2/PaymentPendingPage')
);

export const PayEasePaymentPage = lazyWithRetries(() => import('./components/PayEase/PaymentPage'));

export const PayDollarPendingPage = lazyWithRetries(
  () => import('./components/PayDollar/PendingPage')
);

export const EsewaPaymentPage = lazyWithRetries(
  () => import('./components/Esewa/EsewaPaymentPage')
);
export const EsewaPendingPage = lazyWithRetries(
  () => import('./components/Esewa/EsewaPendingPage')
);

export const PayDollarPaymentPage = lazyWithRetries(
  () => import('./components/PayDollar/PaymentPage')
);
export const PayDollarRedirectPage = lazyWithRetries(
  () => import('./components/PayDollar/RedirectPage')
);

export const DragonPayPaymentPage = lazyWithRetries(
  () => import('./components/DragonPay/DragonPayPaymentPage')
);

export const DragonPayPendingPage = lazyWithRetries(
  () => import('./components/DragonPay/DragonPayPendingPage')
);

export const PayByKlarnaPage = lazyWithRetries(
  () => import('./components/Stripe/Klarna/PayByKlarna')
);

export const SslCommerzPaymentPage = lazyWithRetries(
  () => import('./components/SslCommerz/PaymentPage')
);
export const SslCommerzPendingPage = lazyWithRetries(
  () => import('./components/SslCommerz/PendingPage')
);
export const AlePayPaymentPage = lazyWithRetries(
  () => import('./components/AlePay/AlePayPaymentPage')
);
export const AlePayPendingPage = lazyWithRetries(
  () => import('./components/AlePay/AlePayPendingPage')
);
export const JpmPreparePaymentPage = lazyWithRetries(
  () => import('./components/Jpm/JpmPreparePaymentPage')
);
export const JpmPaymentPage = lazyWithRetries(
  () => import('./components/Jpm/JpmPaymentPage')
);
export const JpmPendingPaymentPage = lazyWithRetries(
  () => import('./components/Jpm/JpmPendingPage')
);

export const OctetPaymentPage = lazyWithRetries(
  () => import('./components/Octet/OctetPaymentPage')
);

export const OctetPendingPage = lazyWithRetries(
  () => import('./components/Octet/OctetPendingPage')
);

export const CyberSourcePaymentPage = lazyWithRetries(
  () => import('./components/CyberSource/CyberSourcePaymentPage')
);

export const CyberSourcePendingPage = lazyWithRetries(
  () => import('./components/CyberSource/CyberSourcePendingPage')
);

export const FiuuPaymentPage = lazyWithRetries(
  () => import('./components/Fiuu/FiuuPaymentPage')
);

export const PaymobPaymentPage = lazyWithRetries(
  () => import('./components/Paymob/PaymobPaymentPage')
);

export const PaymobPendingPage = lazyWithRetries(
  () => import('./components/Paymob/PaymobPendingPage')
);

export const PaymentExpiredPage = lazyWithRetries(
  () => import('./shared/processing/pages/expired/ExpiredPage')
);

export const RedirectMethodPage = lazyWithRetries(
  () => import('./components/redirectMethods/RedirectMethodPage')
);

export const SessionTestPage = lazyWithRetries(() => import('./components/core/SessionTestPage'));

export const PaymentMethodChangeLoadingWrapper = lazyWithRetries(
  () => import('./shared/paymentMethodChanger/PaymentMethodChangeLoadingWrapper')
);
