import { Navigate, Route, useLocation } from 'react-router';
import { PAYMENT_IDENTIFIER_NAME, getQueryParam } from '../utils';

const NavigateToNewRoutes = () => {
  const paymentId = getQueryParam(PAYMENT_IDENTIFIER_NAME);
  return <Navigate to={`/payment?${PAYMENT_IDENTIFIER_NAME}=${paymentId}`} replace={true} />;
};

const NavigateToDragonPayPending = () => {
  const location = useLocation();

  return (
    <Navigate
      to={{ pathname: '/payment/dragonPay/pending', search: location.search }}
      replace={true}
    />
  );
};

export const handleOldRoutes = () => {
  return (
    <>
      <Route path="dragonpay/pending" element={<NavigateToDragonPayPending />} />
      {oldRoutes.map((oldRoute) => {
        return <Route path={oldRoute} key={oldRoute} element={<NavigateToNewRoutes />} />;
      })}
    </>
  );
};

const oldRoutes: string[] = [
  'select/*',
  'sslcommerz/*',
  'paydollar/*',
  'payease/*',
  'paypal/*',
  'jpm/*',
  'adyen/*',
  'esewa/*',
  'seerbitV2/*',
  'cybersource/*',
  'alepay/*',
  'doku/*',
  'dragonpay/*',
  'octet/*',
  'stripe/*',
  'pending/*',
];
