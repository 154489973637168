import * as S from './SummarySegment.styles';

interface SummarySegmentProps {
  children?: React.ReactNode;
}

const SummarySegment = ({ children }: SummarySegmentProps) => {
  return <S.Root elevation={2}>{children}</S.Root>;
};

export default SummarySegment;
