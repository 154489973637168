import {
  subscriptionWithHeaderAndFooter,
  instalmentPlanWithHeaderAndFooter,
  PageType,
} from './components/common';
import {
  SubscriptionFailurePage,
  SubscriptionPayByCard,
  SubscriptionSuccessPage,
  SubscriptionErrorPage,
  InstalmentPlanPayByCard,
  InstalmentPlanErrorPage,
  InstalmentPlanSuccessPage,
  InstalmentPlanStatusPage,
  InstalmentPlanUpdatePaymentMethod,
  SessionTestPage,
} from './LazyLoadComponents';

export const SubscriptionPayByCardRoute = subscriptionWithHeaderAndFooter(SubscriptionPayByCard, {
  withTimer: true,
  withRedirectOnFinishedSubscription: true,
  withBackButton: true,
  withSideSection: true,
  withTitle: true,
  withPaymentMethodChanger: true,
});
export const SubscriptionUpdatePaymentRoute = subscriptionWithHeaderAndFooter(
  SubscriptionPayByCard,
  {
    withTimer: true,
    isUpdatingPaymentMethod: true,
    withBackButton: true,
    withSideSection: true,
    withPaymentMethodChanger: true,
    withTitle: true,
    pageType: PageType.UpdatePaymentMethod,
  }
);
export const SubscriptionFailurePageRoute = subscriptionWithHeaderAndFooter(
  SubscriptionFailurePage,
  {
    withTimer: false,
    isUpdatingPaymentMethod: false,
    pageType: PageType.Status,
  }
);
export const InstalmentPlanPayByCardRoute = instalmentPlanWithHeaderAndFooter(
  InstalmentPlanPayByCard,
  {
    withTimer: true,
    withRedirectOnFinishedInstalmentPlan: true,
    withBackButton: true,
    withSideSection: true,
    withPaymentMethodChanger: true,
    withTitle: true,
  }
);
export const InstalmentPlanSuccessPageRoute =
  instalmentPlanWithHeaderAndFooter(InstalmentPlanSuccessPage);

export const InstalmentPlanUpdatePaymentMethodRoute = instalmentPlanWithHeaderAndFooter(
  InstalmentPlanUpdatePaymentMethod,
  {
    withTimer: true,
    withBackButton: true,
    withSideSection: true,
    withPaymentMethodChanger: true,
    withTitle: true,
    isUpdatingPaymentMethod: true,
    pageType: PageType.UpdatePaymentMethod,
  }
);

export const SubscriptionSuccessPageRoute =
  subscriptionWithHeaderAndFooter(SubscriptionSuccessPage);
export const SubscriptionErrorPageRoute = subscriptionWithHeaderAndFooter(SubscriptionErrorPage, {
  isErrorPage: true,
});
export const InstalmentPlanErrorPageRoute = instalmentPlanWithHeaderAndFooter(
  InstalmentPlanErrorPage,
  {
    isErrorPage: true,
  }
);
export const SubscriptionSessionTestPageRoute = subscriptionWithHeaderAndFooter(SessionTestPage, {
  isErrorPage: false,
});
export const InstalmentPlanSessionTestPageRoute = instalmentPlanWithHeaderAndFooter(
  SessionTestPage,
  {
    isErrorPage: false,
  }
);
export const InstalmentPlanStatusRoute = instalmentPlanWithHeaderAndFooter(
  InstalmentPlanStatusPage,
  {
    isErrorPage: false,
    withTitle: true,
    pageType: PageType.Status,
  }
);
