import * as Sentry from '@sentry/react';
import { AppSettings } from '../../utils/appSettings';
import { EntityType, EventLog } from '../../utils/logger-utils';
import { INSTALMENT_PLAN_IDENTIFIER_NAME, PAYMENT_IDENTIFIER_NAME, SUBSCRIPTION_IDENTIFIER_NAME, getQueryParam } from '../../utils';
import { Integration } from '@sentry/types';

let initialized = false;
const debugModeLocalStorage = 'nppDebugInfo';
const debugModeQueryParameter = 'dbg';

interface DebugModeInfo {
  id: string;
  timestamp: number;
}

export const initSentry = (appSettings: AppSettings, ip: string) => {
  const debugMode = checkDebugMode();
  Sentry.init({
    dsn: appSettings.sentry.dsn,
    tunnel: '/api/log/saveLogs',
    integrations: getSentryIntegrations(debugMode),
    environment: appSettings.environment,
    release: appSettings.release,
    initialScope: (scope) => {
      scope.setUser({ ip_address: ip });
      scope.setTags({
        version: appSettings.version,
      });
      return scope;
    },
    ...getSentrySamplingRates(debugMode, appSettings),
  });

  initialized = true;
};

const setDebugMode = (): void => {
  const now = new Date().getTime();
  const item: DebugModeInfo = {
    id: getId(),
    timestamp: now,
  };
  localStorage.setItem(debugModeLocalStorage, JSON.stringify(item));
};

const getDebugModeItem = (): DebugModeInfo | null => {
  const dbgInfo = localStorage.getItem(debugModeLocalStorage);
  if (!dbgInfo) return null;

  return JSON.parse(dbgInfo);
};

const checkDebugMode = (): boolean => {
  const debugMode = getQueryParam(debugModeQueryParameter);
  if (debugMode) {
    setDebugMode();
    return true;
  }

  const debugModeItem = getDebugModeItem();
  if (!debugModeItem) return false;

  const now = new Date().getTime();
  if (now - debugModeItem.timestamp > 24 * 60 * 60 * 1000) {
    localStorage.removeItem(debugModeLocalStorage);
    return false;
  }

  return debugModeItem.id === getId();
};

const getSentryIntegrations = (debugMode: boolean): Integration[] => {
  const integrations: Integration[] = [Sentry.browserTracingIntegration()];

  if (debugMode) {
    integrations.push(
      Sentry.replayIntegration({
        maskAllInputs: true,
        maskAllText: true,
      })
    );
  }
  return integrations;
};

const getId = (): string =>
  getQueryParam(PAYMENT_IDENTIFIER_NAME) ||
  getQueryParam(SUBSCRIPTION_IDENTIFIER_NAME) ||
  getQueryParam(INSTALMENT_PLAN_IDENTIFIER_NAME) ||
  '';

const getSentrySamplingRates = (debugMode: boolean, appSettings: AppSettings) => {
  const tracesSampleRate = debugMode ? 0.6 : appSettings.sentry.tracesSampleRate;
  const replaysSessionSampleRate = debugMode ? 1.0 : 0.0;
  const replaysOnErrorSampleRate = debugMode ? 1.0 : 0.0;

  return {
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  };
};

export const configureSentryTracking = (
  entityId: string,
  entityType: EntityType,
  correlationSessionId: string
) =>
  Sentry.setTags({
    entityId: entityId,
    entityType: entityType,
    correlationSessionId: correlationSessionId,
  });

export const logErrorToSentry = (error: any) => {
  //React sometimes pack error in additional object
  if (error.exception) {
    error = error.exception;
  }

  if (initialized) {
    Sentry.captureException(error);
  }
};

export const logEventToSentry = (event: EventLog) => {
  if (initialized) {
    Sentry.captureEvent(event);
  }
};
