import { Button } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const StatusButton = styled(Button)`
  width: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: fit-content;
  }
`;

export const StatusButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
  }
`;

export const StatusButtonLinkWrapper = styled.a`
  display: inline;
`;
