import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { CustomizationData } from '../client/base';
import resourcesToBackend from 'i18next-resources-to-backend';

interface Language {
  code: string;
  name: string;
}

const BASE_NPP_LANGUAGE_BUNDLE_CODE = 'en';

export const AVAILABLE_TRANSLATIONS: Language[] = [
  { code: 'en', name: 'English' },
  { code: 'ar', name: 'عربي' },
  { code: 'es', name: 'Español' },
  { code: 'it', name: 'Italiano' },
  { code: 'fr', name: 'Français' },
  { code: 'ko', name: '한국어' },
  { code: 'pt', name: 'Português' },
  { code: 'pl', name: 'Polski' },
  { code: 'bg', name: 'български' },
  { code: 'vi', name: 'Tiếng Việt' },
  { code: 'bn', name: 'বাংলা' },
  { code: 'zh', name: '中文 (简体)' },
  { code: 'zh-Hant', name: '正體中文 (繁體)' },
];

// declare custom type options so the return is always a string.
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18next.use(LanguageDetector).init({
  detection: { caches: [] },
});

i18next
  .use(initReactI18next)
  .use(resourcesToBackend((language: string) => import(`../resources/locale/${language}/npp.json`)))
  .init({
    fallbackLng: BASE_NPP_LANGUAGE_BUNDLE_CODE,
    returnEmptyString: false,
    debug: false,
    interpolation: { escapeValue: false }, // React already does escaping
    ns: 'common',
    defaultNS: 'common',
    returnNull: false,
  });

export const getTranslatedLanguages = (languageList?: string[]): Language[] => {
  let filteredLanguages =
    languageList && languageList.length > 0
      ? AVAILABLE_TRANSLATIONS.filter((lang) => languageList.includes(lang.code))
      : AVAILABLE_TRANSLATIONS;

  //EN is default language of NPP and must be on the list
  if (!filteredLanguages.some((lang) => lang.code === BASE_NPP_LANGUAGE_BUNDLE_CODE)) {
    const en = AVAILABLE_TRANSLATIONS.find((lang) => lang.code === BASE_NPP_LANGUAGE_BUNDLE_CODE);
    if (en) {
      filteredLanguages = [en, ...filteredLanguages];
    }
  }

  return filteredLanguages;
};

export const setLanguage = (language: string) => {
  i18next.changeLanguage(language);
  document.dir = i18next.dir(language);
};

export const storeLanguage = (entityId: string, languageCode: string) => {
  localStorage.setItem(`language-${entityId}`, languageCode);
};

export const getStoredLanguage = (entityId: string) => {
  return localStorage.getItem(`language-${entityId}`);
};

export const initializeTranslationForWrapper = (
  entityId: string,
  customizationData?: CustomizationData
) => {
  const lang = getDefaultLanguage(entityId, customizationData);
  setLanguage(lang);
  storeLanguage(entityId, lang);
};

const checkIsLanuageAvaliable = (languageCode: string): boolean => {
  return AVAILABLE_TRANSLATIONS.some((x) => x.code === languageCode);
};

export const getDefaultLanguage = (
  entityId: string,
  customizationData?: CustomizationData
): string => {
  const storedLanguage = getStoredLanguage(entityId);
  if (storedLanguage) {
    return storedLanguage;
  }

  if (customizationData) {
    const defaultLanguage = customizationData.defaultLanguageCode;
    if (defaultLanguage && checkIsLanuageAvaliable(defaultLanguage)) {
      return defaultLanguage;
    }
  }

  const browserLanguage = i18next.resolvedLanguage;
  if (
    browserLanguage &&
    checkIsLanuageAvaliable(browserLanguage) &&
    customizationData?.languageList?.includes(browserLanguage)
  ) {
    return browserLanguage;
  }

  return BASE_NPP_LANGUAGE_BUNDLE_CODE;
};

export const getTranslationSettingsForHeader = (
  entityId: string,
  isTranslationAllowed: boolean,
  customizationData: CustomizationData
) => {
  return {
    enabled: isTranslationAllowed && !!customizationData.languageList?.length,
    availableLanguages: getTranslatedLanguages(customizationData.languageList),
    defaultLanguageCode: getDefaultLanguage(entityId, customizationData),
    onLanguageChange: (lang: string) => {
      setLanguage(lang);
      storeLanguage(entityId, lang);
    },
  };
};

export default i18next;
