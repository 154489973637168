import * as S from './CommonSubscriptionSideSection.styles';
import useCurrentBreakpoint from '@britishcouncil/react-extro-ui/dist/hooks/useCurrentBreakpoint';
import { TimerSection, TimerSectionProps } from '../../../timer';
import PlanSummary, { PlanSummaryProps } from '../../../summary/planSummary/PlanSummary';
import { DiscountInfo } from '../../../summary/planSummary/components';

interface CommonSubscriptionSideSectionProps {
  timer?: TimerSectionProps;
  planSummary?: PlanSummaryProps;
  discountInfo?: DiscountInfo;
  isUpdatingPaymentMethod?: boolean;
}

const CommonSubscriptionSideSection = ({
  timer,
  planSummary,
  discountInfo,
  isUpdatingPaymentMethod,
}: CommonSubscriptionSideSectionProps) => {
  const breakpoint = useCurrentBreakpoint();

  if (breakpoint === 'lg') {
    return (
      <S.StickyRoot className="sticky">
        {timer && <TimerSection {...timer} />}
        {planSummary && (
          <PlanSummary
            {...planSummary}
            discountInfo={discountInfo}
            isUpdatingPaymentMethod={isUpdatingPaymentMethod}
          />
        )}
      </S.StickyRoot>
    );
  }

  return (
    <>
      {timer && (
        <S.StickyRoot className="sticky">
          <TimerSection {...timer} />
        </S.StickyRoot>
      )}
      <S.Root>
        {planSummary && (
          <PlanSummary
            {...planSummary}
            discountInfo={discountInfo}
            isUpdatingPaymentMethod={isUpdatingPaymentMethod}
          />
        )}
      </S.Root>
    </>
  );
};

export default CommonSubscriptionSideSection;
