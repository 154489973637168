import { useTranslation } from 'react-i18next';
import { formatUTCDate } from '../../../shared/dataFormater';
import { InstalmentStatusValue, InstalmentSummaryItem } from '../../../services';
import { InstalmentStatusLabel } from './InstalmentStatusLabel';
import { InstalmentStatus } from '../../../client/base';
import { useMemo } from 'react';
import useCurrentBreakpoint from '@britishcouncil/react-extro-ui/dist/hooks/useCurrentBreakpoint';
import { StyledTable } from '../../../shared/styledTable';
import { randomUuid } from '../../../utils';

interface Props {
  id?: string;
  instalments: InstalmentSummaryItem[];
  withStatuses?: boolean;
}

const PaymentPlan = ({ id, instalments, withStatuses }: Props) => {
  const { t } = useTranslation(['npp']);
  const breakpoint = useCurrentBreakpoint();

  const shouldShowPaymentDescription = useMemo(() => {
    return instalments.some((x) => !!x.paymentDescription);
  }, [instalments]);

  const shouldShowNumber = useMemo(() => {
    return breakpoint !== 'xs' && breakpoint !== 'sm';
  }, [breakpoint]);

  return (
    <StyledTable.Root id={id}>
      <StyledTable.Table>
        <thead>
          <StyledTable.Tr>
            {shouldShowNumber && (
              <StyledTable.Th nowrap align="center">
                {t('npp:general.number')}
              </StyledTable.Th>
            )}
            {shouldShowPaymentDescription && (
              <StyledTable.Th nowrap>
                {t('npp:stripe.instalmentPlan.paymentDescription')}
              </StyledTable.Th>
            )}
            <StyledTable.Th align="center" nowrap>
              {t('npp:stripe.instalmentPlan.dueDate')}
            </StyledTable.Th>
            <StyledTable.Th align="right" nowrap>
              {t('npp:stripe.instalmentPlan.amount')}
            </StyledTable.Th>
            {withStatuses && (
              <StyledTable.Th nowrap align="center">
                {t('npp:stripe.instalmentPlan.status')}
              </StyledTable.Th>
            )}
          </StyledTable.Tr>
        </thead>
        <tbody>
          {instalments?.map((instalment, index) => (
            <StyledTable.Tr key={randomUuid()}>
              {shouldShowNumber && (
                <StyledTable.Td nowrap align="center">
                  {++index}
                </StyledTable.Td>
              )}
              {shouldShowPaymentDescription && (
                <StyledTable.Td data-test-id={`instalment-payment-description-${index}`}>
                  {instalment.paymentDescription ?? ' - '}
                </StyledTable.Td>
              )}
              <StyledTable.Td nowrap data-test-id={`instalment-date-${index}`} align="center">
                {formatUTCDate(instalment.dueDate)}
              </StyledTable.Td>
              <StyledTable.Td align="right" data-test-id={`instalment-amount-${index}`} nowrap>
                {instalment.amountFormattedWithCurrency}
              </StyledTable.Td>
              {withStatuses && (
                <StyledTable.Td data-test-id={`instalment-status-${index}`} align="center" nowrap>
                  <InstalmentStatusLabel
                    value={InstalmentStatus[instalment.instalmentStatus] as InstalmentStatusValue}
                  />
                </StyledTable.Td>
              )}
            </StyledTable.Tr>
          ))}
        </tbody>
      </StyledTable.Table>
    </StyledTable.Root>
  );
};

export default PaymentPlan;
