import {
  GenericErrorPath,
  InstalmentPlanErrorPath,
  PaymentErrorPagePath,
  SubscriptionErrorPagePath,
} from '../../routes/PathConsts';
import { QueryParams } from '../../utils';
import { AvailablePaymentMethod } from './PaymentMethodChanger';

const generateUrlForPaymentMethod = (
  queryParams: QueryParams,
  paymentMethod: AvailablePaymentMethod,
  additionalSegment?: string
): string => {
  const isValid =
    paymentMethod?.shortCode &&
    paymentMethod?.providerCode &&
    (queryParams.getPaymentId() ||
      queryParams.getSubscriptionId() ||
      queryParams.getInstalmentPlanId());
  if (!isValid)
    if (queryParams.getPaymentId()) return PaymentErrorPagePath;
    else if (queryParams.getSubscriptionId()) return SubscriptionErrorPagePath;
    else if (queryParams.getInstalmentPlanId()) return InstalmentPlanErrorPath;
    else return GenericErrorPath;

  const queryParamsPath = `?${Object.entries(queryParams.getAll())
    .map(([key, value]) => key + '=' + value)
    .join('&')}`;

  const basePath = generateBaseUrlForPaymentMethod(paymentMethod);

  if (additionalSegment) {
    return `${basePath}${additionalSegment}${queryParamsPath}`;
  }

  return `${basePath}${queryParamsPath}`;
};

const generateBaseUrlForPaymentMethod = (paymentMethod: AvailablePaymentMethod) => {
  const paymentMethodSegment =
    paymentMethod.shortCode.toLowerCase() === paymentMethod.providerCode?.toLowerCase()
      ? paymentMethod.shortCode
      : `${paymentMethod.providerCode}/${paymentMethod.shortCode}`;

  return `/payment/${paymentMethodSegment.toLowerCase()}`;
};

const isPathCorrectForPaymentMethod = (path: string, paymentMethod: AvailablePaymentMethod) => {
  const correctPathForPaymentMethod = generateBaseUrlForPaymentMethod(paymentMethod);
  return path.includes(correctPathForPaymentMethod);
};

const getPaymentMethodByPath = (
  path: string,
  availablePaymentMethods: AvailablePaymentMethod[]
) => {
  const pmsWithUrls = availablePaymentMethods.map((pm) => {
    return {
      path: generateBaseUrlForPaymentMethod(pm),
      paymentMethod: pm,
    };
  });

  const exactMatch = pmsWithUrls.find((x) => x.path === path);
  if (exactMatch) return exactMatch;

  return pmsWithUrls.find((x) => path.includes(x.path));
};

export const paymentMethodChangerUtils = {
  generateUrlForPaymentMethod,
  isPathCorrectForPaymentMethod,
  getPaymentMethodByPath,
};
