import styled from '@emotion/styled';
import { spacingFn } from '@britishcouncil/react-extro-ui';
import nppTheme from '../../styles/nppTheme';
import { hexWithOpacity } from '@britishcouncil/react-common';

export const Root = styled.div``;

export const Title = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text.body};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.fontSize.desktop.body};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h6 {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    font-size: ${({ theme }) => theme.fontSize.mobile.body};
  }

  > :last-child {
    text-align: end;
  }
`;

export const List = styled.div`
  font-size: ${({ theme }) => theme.fontSize.mobile.body};
  overflow-wrap: anywhere;
`;

export const Item = styled.div`
  display: flex;
  margin-bottom: ${spacingFn(0.75)};

  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }

  > :last-child {
    text-align: end;
  }
`;

export const ItemLabel = styled.div`
  color: #000;
`;

export const ItemAmount = styled.div`
  margin-inline-start: auto;
  color: ${hexWithOpacity(nppTheme.colors.fontColorDefault, 0.8)};
  min-width: fit-content;
  padding-inline-start: ${({ theme }) => theme.spacing.sm};
`;

export const Total = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.md};

  h5 {
    margin: unset;
    color: #000;
  }
`;

export const Order = styled.small`
  color: ${nppTheme.colors.order.text};
  text-align: center;
  display: block;
`;
