import { Message } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';
import nppTheme from '../styles/nppTheme';
import { hexWithOpacity } from '@britishcouncil/react-common';

export const Root = styled(Message)`
  background: ${hexWithOpacity(nppTheme.colors.timer, 0.5)};
  border-radius: ${({ theme }) => theme.spacing.lg} 0 ${({ theme }) => theme.spacing.lg} 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md};
`;

export const Content = styled.h4`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.md};
`;
