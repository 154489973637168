import { useTranslation } from 'react-i18next';
import SummarySegment from '../common/SummarySegment';
import { SummaryTitle } from '../common';
import SummaryProductList, { LineItem } from '../common/SummaryProductList';
import { Segment } from '@britishcouncil/react-extro-ui';

export interface ProductsSummaryProps {
  orderId: string;
  total: string;
  items?: LineItem[];
}

const ProductsSummary = ({ total, items, orderId }: ProductsSummaryProps) => {
  const { t } = useTranslation(['npp']);

  const withTitle = !!items?.length;

  return (
    <SummarySegment>
      <Segment color="blue" elevation={0}>
        {withTitle && <SummaryTitle>{t('npp:productsSummary.titles.purchase')}</SummaryTitle>}
        <Segment elevation={0}>
          <SummaryProductList
            columnHeaders={[t('npp:productsSummary.name'), t('npp:productsSummary.subtotal')]}
            orderId={orderId}
            total={total}
            items={items}
          />
        </Segment>
      </Segment>
    </SummarySegment>
  );
};

export default ProductsSummary;
