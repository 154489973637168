import { Status, StatusColor } from '@britishcouncil/react-extro-ui';
import { InstalmentStatusValue } from '../../../services';
import { useTranslation } from 'react-i18next';

interface Props {
  value: InstalmentStatusValue;
}

export const InstalmentStatusLabel = ({ value }: Props) => {
  const { t } = useTranslation(['npp']);

  let color: StatusColor;

  switch (value) {
    case 'Initial':
      color = 'grey';
      break;
    case 'Pending':
      color = 'blue';
      break;
    case 'Due':
      color = 'yellow';
      break;
    case 'Cancelled':
      color = 'red';
      break;
    case 'Paid':
      color = 'green';
      break;
    default:
      color = 'grey';
      break;
  }

  const text = t(`npp:instalmentPlanStatusPage.instalmentStatus.${value.toLowerCase()}`);

  return <Status content={text} color={color} />;
};
