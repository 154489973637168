import { ReactNode } from 'react';
import { PaymentMethodChangeStatus, usePaymentMethodContext } from '../contexts';
import { useQueryParams } from '../hooks';
import { paymentMethodChangerUtils } from '../shared/paymentMethodChanger/paymentMethodChangerUtils';
import { goToUrl } from './utils';

export const CustomCspReload = ({ children }: { children: ReactNode }) => {
  const providersWithCustomCsp = ['Fiuu', 'Jpm'];

  const { chosenPaymentMethod, status } = usePaymentMethodContext();

  const queryParams = useQueryParams();

  if (chosenPaymentMethod?.providerCode && status == PaymentMethodChangeStatus.Success) {
    if (providersWithCustomCsp.includes(chosenPaymentMethod.providerCode)) {
      if (!queryParams.isMarkedAsCspUpdate()) {
        queryParams.markUrlWithCspUpdate();
        goToUrl(
          paymentMethodChangerUtils.generateUrlForPaymentMethod(queryParams, chosenPaymentMethod)
        );
      }
    } else if (queryParams.isMarkedAsCspUpdate()) {
      queryParams.removeCspUpdateMark();
      goToUrl(
        paymentMethodChangerUtils.generateUrlForPaymentMethod(queryParams, chosenPaymentMethod)
      );
    }
  }

  return <>{children}</>;
};
