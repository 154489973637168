import { useEffect } from 'react';
import styled from '@emotion/styled';
import { removeInitialLoader } from '../utils';

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ::after {
    --bc-color-loader-grey: #999;
    content: ' ';
    display: inline-block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 5px solid var(--bc-color-loader-grey);
    border-color: var(--bc-color-loader-grey) transparent;
    animation: 1.2s linear infinite lds-dual-ring;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loader = () => {
  useEffect(() => {
    return () => {
      removeInitialLoader();
    };
  });

  return <StyledLoader aria-label="Loading contents" />;
};

export default Loader;
