import React from 'react';
import { logError, logEvent } from './logger-utils';
import { goToErrorPage } from './utils';

const RETRY_TIMES: number = 5;
const RETRY_INTERVAL: number = 1000;

const logLazyLoadingError = (retryCount: number, retryType: string, error: any) => {
  logEvent({
    message: `Asset loading exception ${retryCount}`,
    level: 'warning',
    extra: { retryType, error },
  });
};

const logSuccesfulRetry = () => {
  logEvent({
    message: `Asset retry loading succesful`,
    level: 'info',
  });
};

export const lazyWithRetries: typeof React.lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error: any) {
      logError(error);
      try {
        let reloadResult;
        const urlMatch = /https?:\/\/[^ ]*/.exec(error.message);
        if (urlMatch) {
          reloadResult = await retryWithUrl(importer, urlMatch);
        } else {
          reloadResult = await retryWithoutUrl(importer);
        }
        logSuccesfulRetry();
        return reloadResult;
      } catch (e: any) {
        logError(e);
      }
      goToErrorPage(error);
    }
  };
  return React.lazy(retryImport);
};

//https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
export const retryWithUrl = async <R>(importer: () => Promise<R>, urlMatch: RegExpExecArray) => {
  for (let i = 0; i < RETRY_TIMES; i++) {
    await new Promise((resolve) => setTimeout(resolve, RETRY_INTERVAL * 2 ** i));
    const url = new URL(urlMatch[0]);
    // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
    url.searchParams.set('t', `${+new Date()}`);
    try {
      return await import(/* @vite-ignore */ url.href);
    } catch (e) {
      logLazyLoadingError(i, 'url', e);
      logError(e);
    }
  }
  throw new Error('Failed to retry with url');
};

export const retryWithoutUrl = <R>(
  importer: () => Promise<R>,
  { retries = RETRY_TIMES, interval = RETRY_INTERVAL } = {}
) => {
  return new Promise<R>((resolve, reject) => {
    importer()
      .then(resolve)
      .catch((error) => {
        logLazyLoadingError(retries, 'withoutUrl', error);
        setTimeout(() => {
          if (retries === 1) {
            reject(new Error('Failed to retry without url'));
          }
          retryWithoutUrl(importer, {
            retries: retries - 1,
            interval: interval * 2,
          }).then(resolve, reject);
        }, interval);
      });
  });
};
