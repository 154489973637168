import { ReactNode } from 'react';
import { Button } from '@britishcouncil/react-extro-ui';
import * as S from './ModalWithButtons.styles';
import { Divider } from '../../../shared/divider';

export interface ModalWithButtonsProps {
  title: ReactNode;
  body: ReactNode;
  rightButtonText?: string;
  leftButtonText?: string;
  rightButtonId?: string;
  leftButtonId?: string;
  onClose: () => void;
  onRightButtonClick?: () => void;
  onLeftButtonClick?: () => void;
}

export const ModalWithButtons = ({
  title,
  body,
  rightButtonText,
  leftButtonText,
  rightButtonId,
  leftButtonId,
  onClose,
  onRightButtonClick,
  onLeftButtonClick,
}: ModalWithButtonsProps) => {
  return (
    <S.NppModal visible isOpen onClose={onClose}>
      <S.Root>
        <h4>{title}</h4>
        <S.Body>{body}</S.Body>
        <Divider />
        <S.ButtonsContainer>
          {rightButtonText && (
            <Button id={rightButtonId} onClick={onRightButtonClick} variant="primary">
              {rightButtonText}
            </Button>
          )}
          {leftButtonText && (
            <Button id={leftButtonId} onClick={onLeftButtonClick} variant="discreet">
              {leftButtonText}
            </Button>
          )}
        </S.ButtonsContainer>
      </S.Root>
    </S.NppModal>
  );
};
