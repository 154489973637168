import { FiInfo } from 'react-icons/fi';
import * as S from './TrialInfo.styles';
import { useTranslation } from 'react-i18next';

interface TrialInfoProps {
  unit?: string;
  count?: number;
}

const TrialInfo = ({ count, unit }: TrialInfoProps) => {
  const { t } = useTranslation(['npp']);

  const formatTrialPeriodInfo = (): string => {
    switch (unit) {
      case 'day':
        return t('npp:stripe.subscription.intervalUnit.dayPeriod', { count: count });
      case 'month':
        return t('npp:stripe.subscription.intervalUnit.monthPeriod', { count: count });
      case 'year':
        return t('npp:stripe.subscription.intervalUnit.yearPeriod', { count: count });
      default:
        return '';
    }
  };

  return (
    <S.Root variant="info" hideIcon>
      <S.ContentWrapper>
        <S.Content data-clarity-unmask="True">
          <FiInfo />
          {t('npp:stripe.subscription.trialInfo', {
            length: formatTrialPeriodInfo(),
          })}
        </S.Content>
      </S.ContentWrapper>
    </S.Root>
  );
};

export default TrialInfo;
