import styled from '@emotion/styled';

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span<{
  big?: boolean;
}>`
  color: #000;
  font-weight: 700;
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing.sm};
  align-items: center;
  font-size: ${({ theme, big }) => (big ? theme.fontSize.desktop.h5 : theme.fontSize.desktop.body)};
`;

export const Value = styled.span<{
  big?: boolean;
  bold?: boolean;
}>`
  color: #000;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-size: ${({ theme, big }) => (big ? theme.fontSize.desktop.h5 : theme.fontSize.desktop.body)};
`;
