import SummarySegment from '../common/SummarySegment';
import * as S from './PlanSummary.styles';
import { useTranslation } from 'react-i18next';
import FirstChargeSection from './components/FirstChargeSection';
import DiscountInfoSection, { DiscountInfo } from './components/DiscountInfoSection';
import {
  FullWidthSegment,
  SummaryProductListOrder,
  SummarySubtitle,
  SummarySubtitleExtra,
  SummarySubtitleWrapper,
  SummaryTitle,
} from '../common';
import { Segment } from '@britishcouncil/react-extro-ui';
import { randomUuid } from '../../../utils';

interface PlanInfo {
  trialIntervalCount: number;
  pricingIntervalCount: number;
  pricingIntervalUnit?: string;
  trialIntervalUnit?: string;
  description?: string;
  amountFormattedWithCurrency?: string;
  orderId: string;
}
export interface PlanSummaryProps {
  planInfo: PlanInfo;
  discountInfo?: DiscountInfo;
  isUpdatingPaymentMethod?: boolean;
}

const PlanSummary = ({ planInfo, discountInfo, isUpdatingPaymentMethod }: PlanSummaryProps) => {
  const { t } = useTranslation(['npp']);

  const {
    pricingIntervalCount,
    amountFormattedWithCurrency,
    description,
    pricingIntervalUnit,
    trialIntervalCount,
    trialIntervalUnit,
    orderId,
  } = planInfo;
  const getItems = () => {
    if (!description) {
      return [];
    }

    const splitDescription = description.split('+');
    const items = splitDescription.map((x) => x.replaceAll('+', '').trim());
    return items.filter((x) => x.length > 0);
  };

  const items = getItems();

  const recurringUnit = ` / ${
    pricingIntervalCount > 1 ? pricingIntervalCount : ''
  } ${pricingIntervalUnit}`;

  return (
    <SummarySegment>
      <Segment color="blue" elevation={0}>
        <div data-clarity-unmask="True">
          <SummaryTitle>{t('npp:subscription.summary.title')}</SummaryTitle>
          <SummarySubtitleWrapper data-test-id="subscription-summary">
            <SummarySubtitle data-clarity-unmask="True">
              {amountFormattedWithCurrency}
            </SummarySubtitle>
            <SummarySubtitleExtra data-clarity-unmask="True">{recurringUnit}</SummarySubtitleExtra>
          </SummarySubtitleWrapper>
        </div>
        {items.length > 0 && (
          <Segment elevation={0}>
            <S.ListTitle>{t('npp:subscription.summary.listTitle')}:</S.ListTitle>
            <S.List>
              {items.map((item) => (
                <S.ListItem  key={randomUuid()} data-clarity-unmask="True">{item}</S.ListItem>
              ))}
            </S.List>
            <SummaryProductListOrder>
              {t('npp:productsSummary.orderId')}:  <span data-clarity-mask="True">{orderId}</span>
            </SummaryProductListOrder>
          </Segment>
        )}
      </Segment>
      {!isUpdatingPaymentMethod && discountInfo && (
        <FullWidthSegment elevation={0}>
          <DiscountInfoSection discount={discountInfo} />
        </FullWidthSegment>
      )}
      <Segment elevation={0}>
        <FirstChargeSection
          amountFormattedWithCurrency={amountFormattedWithCurrency}
          trialIntervalCount={trialIntervalCount}
          trialIntervalUnit={trialIntervalUnit}
          recurringUnit={recurringUnit}
          discount={discountInfo}
          isUpdatingPaymentMethod={isUpdatingPaymentMethod}
        />
      </Segment>
    </SummarySegment>
  );
};

export default PlanSummary;
