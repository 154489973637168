import { Message, spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';
import { StatusSegmentType } from '.';

export const Root = styled(Message)<{ status: StatusSegmentType }>`
  padding: ${spacingFn(5.5)};

  h4,
  svg {
    color: ${({ status, theme }) => {
      switch (status) {
        case 'success':
          return theme.colors.petrol;
        case 'error':
          return theme.colors.red;
        case 'pending':
          return theme.colors.indigo;
        case 'warning':
          return theme.colors.brown;
      }
    }};
  }
`;

export const StatusPayload = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  h4 {
    color: inherit;
  }
`;
