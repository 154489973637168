import { spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const Root = styled.div``;

export const ListTitle = styled.strong`
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const List = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  padding-inline-start: ${spacingFn(1.5)};
  overflow-wrap: anywhere;
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.darkGrey};
`;
