import { CountdownTimeDelta } from 'react-countdown';
import { Timer } from './Timer';
import { TimerAlert } from './TimerAlert';
import { useCallback, useMemo, useState } from 'react';

export interface TimerSectionProps {
  hidden?: boolean;
  timeoutDate: Date;
  onComplete: () => void;
}

export const TimerSection = ({ timeoutDate, hidden, onComplete }: TimerSectionProps) => {
  const alertTimeSpan = useMemo(() => {
    const alertDuration = 6 * 1000; // 5 seconds (+1s for animation) - duration when alert is displayed
    const timeToAppear = 5 * 1000 * 60; // 5 minutes - middle time for alert to be displayed
    return [timeToAppear - alertDuration, timeToAppear];
  }, []);

  const [isBeforeAlert, setIsBeforeAlert] = useState(
    new Date().getTime() < timeoutDate.getTime() - alertTimeSpan[1]
  );
  const [isAfterAlert, setIsAfterAlert] = useState(
    new Date().getTime() > timeoutDate.getTime() - alertTimeSpan[0]
  );

  const onTick = useCallback(
    (timeDelta: CountdownTimeDelta) => {
      if (timeDelta.total < alertTimeSpan[1]) setIsBeforeAlert(false);
      if (timeDelta.total < alertTimeSpan[0]) setIsAfterAlert(true);
    },
    [alertTimeSpan]
  );

  return (
    <div>
      {<TimerAlert hidden={hidden || isBeforeAlert || isAfterAlert} />}
      <Timer
        hidden={hidden}
        timeoutDate={timeoutDate}
        onComplete={onComplete}
        onTick={onTick}
        warningStyleEnabled={isAfterAlert}
      />
    </div>
  );
};
