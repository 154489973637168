import { DateTime } from 'luxon';

type DateType = Date | DateTime | string;
type NullableDateType = DateType | undefined | null;

/** This function always return luxon DateTime, but accept Date, DateTime, string.
 * Especially useful when axios returns string instead of Date */
export const toDateTime = (date: DateType): DateTime => {
  if (date instanceof DateTime) {
    return date;
  } else if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  } else if (typeof date === 'string') {
    const withZulu = DateTime.fromISO(`${date}Z`);
    return withZulu.isValid ? withZulu : DateTime.fromISO(date);
  } else {
    throw new Error(`Cannot create date from type: ${typeof date}`);
  }
};

export const formatUTCDate = (date: NullableDateType, nullString?: string) => {
  if (!date) return nullString === undefined ? '—' : nullString;
  return toDateTime(date).setLocale('en-gb').toLocaleString(DateTime.DATE_SHORT);
};

export const formatUTCDateTime = (date: NullableDateType, nullString?: string) => {
  if (!date) return nullString === undefined ? '—' : nullString;
  return toDateTime(date).setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
};
