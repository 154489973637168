import * as S from './FirstChargeSection.styles';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmPaymentButton } from '../../../../components';
import { DiscountInfo } from './DiscountInfoSection';
import { randomUuid } from '../../../../utils';

interface FirstChargeSectionProps {
  amountFormattedWithCurrency?: string;
  trialIntervalCount?: number;
  trialIntervalUnit?: string;
  recurringUnit?: string;
  discount?: DiscountInfo;
  isUpdatingPaymentMethod?: boolean;
}

const FirstChargeSection = ({
  amountFormattedWithCurrency,
  trialIntervalCount,
  trialIntervalUnit,
  recurringUnit,
  discount,
  isUpdatingPaymentMethod,
}: FirstChargeSectionProps) => {
  const { t } = useTranslation(['npp']);
  const isDiscountExpiring = !!discount?.durationInPeriods;

  const formatTrialPeriodInfo = (): string => {
    switch (trialIntervalUnit) {
      case 'day':
        return t('npp:subscription.intervalUnit.dayPeriod', { count: trialIntervalCount });
      case 'month':
        return t('npp:subscription.intervalUnit.monthPeriod', { count: trialIntervalCount });
      case 'year':
        return t('npp:subscription.intervalUnit.yearPeriod', { count: trialIntervalCount });
      default:
        return '';
    }
  };

  const getRecurringInfo = () => {
    const sectionTranslations: { key: string; arguments: {}; components: {} }[] = [];
    const defaultTranslation = {
      key: 'npp:subscription.firstCharge.recurringOf',
      arguments: {
        amount: discount
          ? discount.discountedAmountFormattedWithCurrency
          : amountFormattedWithCurrency,
        periods: recurringUnit,
      },
      components: { bold: <strong /> },
    };

    if (!discount || !isDiscountExpiring) {
      sectionTranslations.push(defaultTranslation);
    } else {
      if (discount.durationInPeriods! > 1) {
        sectionTranslations.push({
          ...defaultTranslation,
          key: 'npp:subscription.firstCharge.recurringOfFirst',
          arguments: {
            ...defaultTranslation.arguments,
            periods: discount.durationInPeriods,
            amount: discount.discountedAmountFormattedWithCurrency,
          },
        });
      }

      sectionTranslations.push({
        ...defaultTranslation,
        key: 'npp:subscription.firstCharge.recurringOfNext',
        arguments: {
          ...defaultTranslation.arguments,
          amount: amountFormattedWithCurrency,
        },
      });
    }

    return (
      <>
        {sectionTranslations.map((t) => (
          <S.FirstChargeRecurringInfo data-clarity-unmask="True" key={randomUuid()}>
            <Trans i18nKey={t.key} values={t.arguments} components={t.components} />
          </S.FirstChargeRecurringInfo>
        ))}
      </>
    );
  };

  return (
    <>
      {!isUpdatingPaymentMethod && (
        <S.FirstChargeTitleWrapper>
          <S.FirstChargeTitle data-clarity-unmask="True">
            <h4>
              {t('npp:subscription.firstCharge.title')}
              {!!trialIntervalCount && <span>*</span>}
            </h4>
            <S.FirstChargeAmount data-clarity-unmask="True">
              {discount?.discountedAmountFormattedWithCurrency
                ? discount?.discountedAmountFormattedWithCurrency
                : amountFormattedWithCurrency}
            </S.FirstChargeAmount>
          </S.FirstChargeTitle>
          {!!trialIntervalCount && (
            <S.TrialNote>
              <span data-clarity-unmask="True" data-test-id="trial-info">
                *{t('npp:subscription.firstCharge.trialNote', { period: formatTrialPeriodInfo() })}
              </span>
            </S.TrialNote>
          )}
        </S.FirstChargeTitleWrapper>
      )}
      <ConfirmPaymentButton
        formId="subscriptionForm"
        customText={
          isUpdatingPaymentMethod
            ? t('npp:stripe.subscription.buttonUpdate')
            : t('npp:subscription.buttonActivate')
        }
      />
      {getRecurringInfo()}
    </>
  );
};

export default FirstChargeSection;
