import i18next from 'i18next';
import { FiAlertTriangle } from 'react-icons/fi';
import { Message } from '@britishcouncil/react-extro-ui';
import { ShowConfirmationModalParams } from '@contexts';
import * as S from './commonModals.styles';

export const commonModals = {
  cancelPayment: (): ShowConfirmationModalParams => {
    return {
      title: i18next.t('npp:modals.cancelPayment.cancelPayment'),
      body: i18next.t('npp:modals.cancelPayment.areYouSure'),
      cancelButtonText: i18next.t('npp:modals.cancelPayment.backToPayment'),
      confirmButtonText: i18next.t('npp:modals.cancelPayment.cancelPayment'),
    };
  },
  cancelQRCodePayment: (): ShowConfirmationModalParams => {
    return {
      title: <S.BiggerTitle>{i18next.t('npp:modals.cancelQRCodePayment.attention')}</S.BiggerTitle>,
      body: (
        <Message
          variant={'error'}
          header={<S.StatusPayload>{i18next.t('npp:payease.info')}</S.StatusPayload>}
          icon={<FiAlertTriangle {...{ size: 40, strokeWidth: 1.5 }} />}
          iconAlign={'header'}
        />
      ),
      cancelButtonText: i18next.t('npp:modals.cancelPayment.backToPayment'),
      confirmButtonText: i18next.t('npp:modals.cancelPayment.cancelPayment'),
    };
  },
};
