import { theme } from '@britishcouncil/react-extro-ui/dist/theme/theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Align = 'left' | 'right' | 'center';

const textAlignStyles = (align?: Align) => {
  if (!align) {
    return;
  }

  return css`
    text-align: ${align};
  `;
};

const nowrapStyles = (nowrap?: boolean) => {
  if (!nowrap) {
    return;
  }

  return css`
    white-space: nowrap;
  `;
};

export const Root = styled.div`
  min-width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  background: inherit;
  width: 100%;
  font-size: ${theme.fontSize.default};
`;

export const Tr = styled.tr`
  :not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.lightGrey};
  }
`;

export const Th = styled.th<{ align?: Align; nowrap?: boolean }>`
  font-weight: 700;
  padding: ${theme.spacing.lg} ${theme.spacing.md};
  color: ${theme.colors.indigo};
  ${({ align }) => textAlignStyles(align)};
  ${({ nowrap }) => nowrapStyles(nowrap)}
  border-bottom: 1px solid ${theme.colors.lightGrey};
`;

export const Td = styled.td<{ align?: Align; nowrap?: boolean; }>`
  padding: ${theme.spacing.lg} ${theme.spacing.md};
  overflow-wrap: anywhere;
  ${({ align }) => textAlignStyles(align)};
  ${({ nowrap }) => nowrapStyles(nowrap)}
`;
