import { spacingFn } from '@britishcouncil/react-extro-ui';
import styled from '@emotion/styled';

export const Root = styled.h2`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.lg};
`;

export const UnderLine = styled.div`
  height: 1px;
  width: ${spacingFn(7.5)};
  border: 2px solid ${({ theme }) => theme.colors.indigo};
  border-radius: ${spacingFn(6)};
`;
