import * as S from './PageTitle.styles';

interface PageTitleProps {
  title: string;
}

const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <S.Root>
      {title}
      <S.UnderLine />
    </S.Root>
  );
};

export default PageTitle;
