import styled from '@emotion/styled';
import nppTheme from '../../styles/nppTheme';

export const Root = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  row-gap: ${({ theme }) => theme.spacing.md};
  justify-content: ${({ alignCenter }) => (alignCenter ? 'center' : 'start')};

  ${({ theme }) => theme.mediaQueries.md} {
    row-gap: ${({ theme }) => theme.spacing.lg};
  }
`;
export const Wrapper = styled.div`
  display: flex;
  box-sizing: content-box;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
  }

  position: sticky;
`;
export const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${nppTheme.layout.spacing.paymentPage.gap};
`;

export const SideSection = styled.div`
  display: contents;

  ${({ theme }) => theme.mediaQueries.lg} {
    min-width: 375px;
    max-width: 375px;
    display: block;
    row-gap: ${nppTheme.layout.spacing.paymentPage.gap};
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.lg};
`;
