import { Button, spacingFn } from '@britishcouncil/react-extro-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export type TryAgainButtonSize = 'default' | 'big';

export const Root = styled(Button)<{ btnSize: TryAgainButtonSize }>`
  ${({ theme, btnSize }) => {
    if (btnSize === 'big') {
      return css`
        padding: ${theme.spacing.lg} ${spacingFn(5)};
      `;
    }
  }};
`;
