import { spacingFn } from '@britishcouncil/react-extro-ui/dist/theme/utils/spacingFn';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonBarBody = styled.div`
  span {
    margin-bottom: ${spacingFn(4)};
  }
`;
const SkeletonLoader = () => {
  useEffect(() => {
    return () => {
      const previevLoader = document.getElementById('preview');
      const root = document.getElementById('root');
      if (previevLoader && root) {
        previevLoader.remove();
        root.classList.remove('hidden-root');
      }
    };
  });
  return (
    <>
      <SkeletonBarBody>
        <Skeleton count={1} height={spacingFn(6)} />
        <Skeleton count={3} height={spacingFn(9)} />
      </SkeletonBarBody>
        <Skeleton count={1} height={spacingFn(9)} />
    </>
  );
};

export default SkeletonLoader;
